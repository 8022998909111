import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {Warenkorb} from '../../../../models/warenkorb';
import {AppPageloaderService} from '../../../../services/app.pageloader.service';
import {AppWarenkorbService} from '../../../../services/app.warenkorb.service';
import {AppEventService} from '../../../../services/app.event.service';
import {AppViewService} from '../../../../services/app.view.service';
import {AppDialogsService} from '../../../../services/app.dialogs.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AppShopService} from '../../../../services/app.shop.service';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {Artikel} from '../../../../models/artikel';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';

@Component({
  templateUrl: 'shop.warenkorb-menu.component.html',
  styleUrls: ['shop.warenkorb-menu.component.scss']
})
export class ShopWarenkorbMenuComponent {
  static id = 'ShopWarenkorbMenuComponent';

  add_mode = false;

  newwk: Warenkorb;

  constructor(private loader: AppPageloaderService, public wksvc: AppWarenkorbService,
              private esvc: AppEventService, private cdr: ChangeDetectorRef, private vsvc: AppViewService,
              private dsvc: AppDialogsService, private mdsvc: MatDialog, public ssvc: AppShopService,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: { artikel: Artikel, entry: WarenkorbEntry },
              private _bottomSheetRef: MatBottomSheetRef<ShopWarenkorbMenuComponent>) {
  }


  save() {
    this.loader.start('ShopViewActionComponent_' + this.data.artikel.ArtikelNummer + '_11');
    this.wksvc.createWarenkorb(this.newwk).subscribe(wk => {
      this.loader.stop('ShopViewActionComponent_' + this.data.artikel.ArtikelNummer + '_11');
      this.ssvc.addToWarenkorb(wk, this.data.entry);
      this._bottomSheetRef.dismiss();
    });
  }

  cancel() {
    this.add_mode = false;
    this.newwk = undefined;
  }

  add() {
    this.newwk = new Warenkorb();
    this.newwk.label = 'Neuer Warenkorb';
    this.newwk.shared = true;
    this.add_mode = true;
  }

  addToWarenkorb(wk: Warenkorb, entry: WarenkorbEntry) {
    this._bottomSheetRef.dismiss();
    return this.ssvc.addToWarenkorb(wk, entry);
  }
}
