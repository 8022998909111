<mat-menu #userMenu="matMenu" class="user-menu">
  <button mat-menu-item class="tut_lieferkunde_button" style="height: auto; white-space: normal;" routerLink="/lieferkunden"
          *ngIf="domaincfg.show_customer_numbers && lieferkunde">
    <table>
      <tr>
        <td style="vertical-align: middle; line-height: 18px">
          <svg-icon>lieferadresse</svg-icon>
        </td>
        <td style="line-height: 18px; padding-left: 5px">
          <strong>{{lieferkunde.Vorname}}</strong>
          <br><br>
          <ng-container *ngIf="domaincfg.showA2KndNrOverwrite == 'A2KndNrDefault'">
            {{lieferkunde.A2KndNrDefault}}
          </ng-container>
          <ng-container *ngIf="domaincfg.showA2KndNrOverwrite == 'A2KndNrOrganisationseinheit'">
            {{lieferkunde.A2KndNr}}
          </ng-container>
          <ng-container *ngIf="domaincfg.showA2KndNrOverwrite == 'Nein'">
            {{lieferkunde.LieferkundeNr}}
          </ng-container>
          <br>
          {{lieferkunde.Nachname}}
          <br>
          <small>{{lieferkunde.Strasse|trim}}, {{lieferkunde.Plz}} {{lieferkunde.Ort}}</small>
        </td>
      </tr>
    </table>
  </button>
  <button mat-menu-item class="tut_lieferkunde_button" routerLink="/lieferkunden"
          *ngIf="!domaincfg.show_customer_numbers && lieferkunde">
    <table>
      <tr>
        <td style="vertical-align: middle; line-height: 18px">
          <svg-icon>lieferadresse</svg-icon>
        </td>
        <td style="line-height: 18px; padding-left: 5px">
          <strong>{{lieferkunde.Vorname}}</strong>
          <br><br>
          {{lieferkunde.Nachname}}
          <br>
          <small>{{lieferkunde.Strasse|trim}}, {{lieferkunde.Plz}} {{lieferkunde.Ort}}</small>
        </td>
      </tr>
    </table>
  </button>

  <permission-block [oneOfRoles]="['Kunde']">
    <button routerLink="/customer-settings" *ngIf="lieferkunde" mat-menu-item title="Kunden-Einstellungen ändern">
      <svg-icon>einstellungen</svg-icon>
      Kunden-Einstellungen
    </button>
  </permission-block>

  <button routerLink="/settings" *ngIf="lieferkunde" mat-menu-item title="Benutzer-Einstellungen ändern">
    <svg-icon>einstellungen</svg-icon>
    Benutzer-Einstellungen
  </button>

  <button (click)="restart()" *ngIf="lieferkunde" mat-menu-item title="Neustarten">
    <svg-icon>neustart</svg-icon>
    Neustarten
  </button>

  <button mat-menu-item routerLink="/logout" title="Abmelden">
    <svg-icon>logout</svg-icon>
    Abmelden
  </button>

  <button mat-menu-item (click)="depersonate()" *ngIf="user.impersonated_by" title="Depersonate">
    <svg-icon>logout</svg-icon>
    Depersonate
  </button>
</mat-menu>
<br *ngIf="isMobile|async">
<button *ngIf="user.hasRole('Admin')" mat-button routerLinkActive="active" routerLink="/admin" title="Admin">
  <svg-icon>admin</svg-icon>
</button>
<button [matMenuTriggerFor]="userMenu" class="tut_user_menu" mat-button [class.color_orange]="differential_lieferkunde"
        style="vertical-align: middle">
  <svg-icon *ngIf="user.vertreter">vertreter</svg-icon>
  <svg-icon *ngIf="!user.vertreter">benutzer</svg-icon>
  <span *ngIf="user">{{user.benutzerandruckname}}</span>
</button>

