import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {AppPageloaderService} from '../../../../services/app.pageloader.service';
import {AppPageTitleService} from '../../../../services/app.page-title.service';
import {ActivatedRoute} from '@angular/router';
import {AppKeyValueStorageService} from '../../../../services/app.key-value-storage.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {View} from '../../../../models/view';
import {AppViewService} from '../../../../services/app.view.service';
import {Artikel} from '../../../../models/artikel';
import {ArtikelAktion} from '../../../../models/artikel-aktion';
import {ArtikelWithAktionen} from '../../../../models/artikel-with-aktionen';
import {AppCrontaskService} from '../../../../services/app.crontask.service';
import {AppSettingsService} from '../../../../services/app.app_settings.service';
import {AppEventService} from '../../../../services/app.event.service';
import {DomainVertreterConfig} from '../../../../models/domain-vertreter-config';
import {AppNavbarService} from '../../../../services/app.navbar.service';
import {ShopViewActionComponent} from '../view-action/shop.view-action.component';
import {AppShopService} from '../../../../services/app.shop.service';
import {MatDrawer} from '@angular/material/sidenav';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ShopViewFilterBottomsheetComponent} from '../view-filter-bottomsheet/shop.view-filter-bottomsheet.component';
import {AppLayoutsFullheightComponent} from '../../../../components/layouts/app.layouts.fullheight.component';
import {AppLieferkundeService} from "../../../../services/app.lieferkunde.service";
import {AppMessageService} from "../../../../services/app.message.service";
import {MatomoTracker} from "../../../matamo/service/matomo-tracker.service";
import {takeUntil} from "rxjs/operators";
import {AppMobileService} from "../../../../services/app.mobile.service";
import {AppAsyncTimeout} from "../../../../helpers/app.asyncTimeout";

@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'shop.view.component.html',
  styleUrls: ['shop.view.component.scss']
})
export class ShopViewComponent extends AppLayoutsFullheightComponent implements OnInit, OnDestroy {
  static id = 'ShopViewComponent';
  slug: string;
  view = new BehaviorSubject<View>(null);

  sub_route_active = false;

  drawer_state_opened = new BehaviorSubject<boolean>(true);

  loading = new BehaviorSubject<boolean>(false);
  loading$ = this.loading.asObservable();

  total_count = new BehaviorSubject<number>(0);
  count = new BehaviorSubject<number>(0);

  entries = new BehaviorSubject<Artikel[]>(null);

  visible_artikel_aktionen = new BehaviorSubject<ArtikelWithAktionen>(null);

  vertreterconfig: BehaviorSubject<DomainVertreterConfig> = new BehaviorSubject<DomainVertreterConfig>(null);

  queue = [];

  queue_running = false;

  isMobile = new BehaviorSubject<boolean>(false);

  fsize = 'font-size-1';

  search = '';
  queryParamsearch: undefined;

  layout = new BehaviorSubject<string>('table');
  layoutMobile = new BehaviorSubject<string>('box');
  page_number = 1;
  page_size_1 = 10;

  default_show_elements = 20;

  scrollload_done = false;

  active_filters: string[] = [];

  _destroy = new Subject();

  change_timeout: any;

  @ViewChild('view_scrollbox', {static: false}) scrollbox: any;

  @ViewChild('drawer') drawer: MatDrawer;

  @ViewChild('filterbox') filterbox: ElementRef;

  actioncomps: ShopViewActionComponent[] = [];

  current_viewscroll_height = new BehaviorSubject<any>(null);

  default_viewscroll_height = 0;

  resize_timeout: any;

  show_warning = new BehaviorSubject<boolean>(false);

  entriesToAdd = [];
  addWorkerRunning = false;

  addWorkerTimeout: any;

  nextAfterNextPage = false;

  autoNextLoadTimeout: any;

  constructor(private route: ActivatedRoute, private vsvc: AppViewService, private loader: AppPageloaderService,
              private tsvc: AppPageTitleService, private kvstorage: AppKeyValueStorageService, private ctsvc: AppCrontaskService,
              private settsvc: AppSettingsService, private esvc: AppEventService, private nbsvc: AppNavbarService,
              private cd: ChangeDetectorRef, private ssvc: AppShopService, private bottomsheet: MatBottomSheet,
              private msvc: AppMobileService,
              private lksvc: AppLieferkundeService, private msg: AppMessageService, private tracker: MatomoTracker) {
    super();
  }

  showWarning(state: boolean) {
    this.show_warning.next(state);
  }

  subRouteActivate() {
    this.sub_route_active = true;
  }

  focusNextMenge(called_elem) {
    let idx = this.actioncomps.indexOf(called_elem);
    if (this.actioncomps[idx + 1]) {
      this.actioncomps[idx + 1].focusMenge();
    }
  }

  subRouteDeactivate() {
    this.sub_route_active = false;
    if (this.view.value) {
      this.tsvc.setTitle('Shop - ' + this.view.value.getLabel());
    }
  }

  @HostListener('window:resize', ['$event'])
  detectHeight(event) {

    if (this.scrollbox && this.scrollbox.elRef && this.filterbox && this.filterbox.nativeElement) {
      this.current_viewscroll_height.next(null);
      this.detectChanges();
      if (this.resize_timeout) {
        clearTimeout(this.resize_timeout);
      }
      this.resize_timeout = setTimeout(() => {
        if (this.scrollbox.elRef.nativeElement.offsetHeight !== undefined) {
          this.default_viewscroll_height = this.scrollbox.elRef.nativeElement.offsetHeight;
        }

        //const height = this.filterbox.nativeElement.offsetHeight + 27;
        const height = this.filterbox.nativeElement.offsetHeight + 7;
        const new_height = this.default_viewscroll_height - height;
        if (this.current_viewscroll_height.value !== new_height) {
          this.current_viewscroll_height.next(new_height);
          this.detectChanges();
        }
      }, 1500);
    }
  }

  resetsearch() {
    this.active_filters = [];
    const v = this.view.value;
    v.setActiveFilterKnotenIds(this.active_filters);
    this.view.next(v);
    this.query();
  }

  showFilter() {
    this.bottomsheet.open(ShopViewFilterBottomsheetComponent, {
      data: this
    });

  }

  openDrawer() {
    this.drawer_state_opened.next(true);
    this.saveView();
  }

  closeDrawer() {
    this.drawer_state_opened.next(false);
    this.saveView();
  }

  registerViewActionComponent(comp: ShopViewActionComponent) {
    this.actioncomps.push(comp);
  }

  unregisterViewActionComponent(comp: ShopViewActionComponent) {
    const idx = this.actioncomps.indexOf(comp);
    this.actioncomps.splice(idx, 1);
  }

  addAll(exec_after = null) {
    let obs = new Observable(obs => {
      let entries = [];
      this.actioncomps.forEach(c => {
        if (c.entry.menge > 0 || c.entry.freimenge > 0) {
          entries.push(c.entry);
        }
      });
      if (entries.length > 0) {
        this.ssvc.addMultiToPrimaryWarenkorb(entries, () => {
          obs.next(true);
          obs.complete();
          this.actioncomps.forEach(c => {
              c.reset();
          });
        });
      } else {
        obs.next(true);
        obs.complete();
      }
    });

    obs.subscribe(s => {
      if (s) {
        if (exec_after) {
          AppAsyncTimeout.setTimeout(() => {
            exec_after();
          }, 500);
        }
      }
    });
  }

  saveView() {
    this.kvstorage.save4User('ShopViewComponent_' + this.slug + '_view', {
      active_filters: this.active_filters,
      search: this.search,
      layout: this.layout.value,
      layoutMobile: this.layoutMobile.value,
      ajax_wk_opened: this.drawer_state_opened.value
    }).subscribe();
  }

  loadView(exec_after = null) {
    this.kvstorage.load4User('ShopViewComponent_' + this.slug + '_view').subscribe(last_settings => {
      if (last_settings) {
        this.active_filters = last_settings.active_filters;
        this.search = last_settings.search;
        if (last_settings.ajax_wk_opened) {
          this.drawer_state_opened.next(true);
        } else {
          this.drawer_state_opened.next(false);
        }
        this.layout.next(last_settings.layout);
        if (last_settings.layoutMobile) {
          this.layoutMobile.next(last_settings.layoutMobile);
        }
        const v = this.view.value;
        v.setActiveFilterKnotenIds(this.active_filters);
        this.view.next(v);

        if (exec_after) {
          exec_after(true);
        }

      } else {
        if (exec_after) {
          exec_after(false);
        }
      }
    });
  }

  setBoxLayout() {
    if (this.isMobile.value) {
      this.layoutMobile.next('box');
    } else {
      this.layout.next('box');
    }
    this.saveView();
  }

  setTableLayout() {
    if (this.isMobile.value) {
      this.layoutMobile.next('table');
    } else {
      this.layout.next('table');
    }
    this.saveView();
  }

  changePageSize() {
    this.page_number = 1;
    this.query();
  }

  nextPage(silent=false) {
    let pageSize = this.page_size_1;
    if (!this.scrollload_done) {
      if ((this.page_number * pageSize) < this.total_count.value) {
        this.page_number++;
        this.query(true, silent);
      }
    }
  }

  cancelAddWorker() {
    if (this.addWorkerTimeout) {
      clearTimeout(this.addWorkerTimeout);
    }
    this.entriesToAdd = [];
    this.addWorkerRunning = false;
  }

  addWorker(force=false) {
    if (this.entriesToAdd.length > 0 && (!this.addWorkerRunning || force)) {
      this.addWorkerRunning = true;
      let e = this.entriesToAdd.shift();

      this.entries.next([...this.entries.value, e]);

      if (this.entriesToAdd.length > 0) {
        this.addWorkerTimeout = setTimeout(() => {
          this.addWorker(true);
        });
      } else {
        this.addWorkerRunning = false;
      }
    }
  }

  changePageNumber() {
    this.query();
  }

  showAktion(artikel: Artikel, aktionen: ArtikelAktion[]) {
    const a = new ArtikelWithAktionen();
    a.artikel = artikel;
    a.aktionen = aktionen;
    this.visible_artikel_aktionen.next(a);
  }

  hideAktionen() {
    this.visible_artikel_aktionen.next(null);
  }

  query(add_content = false, silent = false) {
    if (this.autoNextLoadTimeout) {
      clearTimeout(this.autoNextLoadTimeout);
    }
    let pageSize = this.page_size_1;

    if (this.search == '' && !this.view.value.settings.allow_empty_search) {
      this.cancelAddWorker();
      this.entries.next(null);
    } else {
      if (!add_content) {
        this.scrollload_done = false;
        this.page_number = 1;
        if (this.scrollbox && this.scrollbox.SimpleBar) {
          this.scrollbox.SimpleBar.getScrollElement().scrollTop = 0;
        }

        this.tracker.trackSiteSearch(this.search, 'view_' + this.view.value.slug);
        this.cancelAddWorker();
      }
      this.saveView();
      if (!silent) this.loading.next(true);
      this.vsvc.queryBySlug(this.slug, this.page_number, pageSize, this.active_filters, this.search).subscribe(res => {
        if (this.view.value.settings.allow_empty_search == false && this.search == '') {
          this.cancelAddWorker();
          this.entriesToAdd = res.data;
          this.entries.next([]);
          this.addWorker();
          this.count.next(res.count);
        } else {
          if (add_content) {
            if (res.data.length == 0 || res.data.length < pageSize) {
              this.scrollload_done = true;
            }

            if (this.entries.value) {
              res.data.forEach(d => {
                this.entriesToAdd.push(d);
              })
              this.addWorker();
            } else {
              this.cancelAddWorker();
              this.entriesToAdd = res.data;
              this.entries.next([]);
              this.addWorker();
            }

            this.count.next(this.count.value + res.count);

          } else {
            this.entries.next(res.data);
            this.count.next(res.count);
          }
        }

        if (!add_content || !isNaN(res.total_count)) {
          this.total_count.next(res.total_count);
        }

        if (this.count.value < this.default_show_elements && res.total_count > this.count.value) {
          this.autoNextLoadTimeout = setTimeout(() => {
            this.nextPage(true);
          }, 500);
        }

        if (this.nextAfterNextPage) {
          this.nextAfterNextPage = false;
          this.nextPage();
        }

        if (!silent) this.loading.next(false);
      });
    }
  }

  ngOnDestroy(): void {
    this.tsvc.resetTitle();

    this.nbsvc.resetColors();
    this.nbsvc.clear();

    this._destroy.next(true);
    this._destroy.complete();
  }

  doChange() {
    this.active_filters = this.view.value.getActiveFilterKnotenIds();
    if (this.change_timeout) {
      clearTimeout(this.change_timeout);
    }
    this.change_timeout = setTimeout(() => {
      this.query();
    }, 2000);
  }

  ngOnInit(): void {
    this.msvc.isMobile().pipe(takeUntil(this._destroy)).subscribe(m => {
      if (this.isMobile.value != m) {
        this.isMobile.next(m);
        this.detectChanges();
      }
    });
    this.vsvc.$vertreterconfig
      .pipe(takeUntil(this._destroy))
      .subscribe(c => {
      this.vertreterconfig.next(c);
      this.detectChanges();
    });

    this.esvc.getQueue()
      .pipe(takeUntil(this._destroy))
      .subscribe(e => {
        if (e.name == 'gui.ShopCustomerSpecialPriceChanged') {
          this.query();
        }
    });

    this.route.params
      .pipe(takeUntil(this._destroy))
      .subscribe(p => {
      this.slug = p['slug'];
      this.init();
      this.loadSettings();
    });

    this.route.queryParams
      .pipe(takeUntil(this._destroy))
      .subscribe(p => {
        if (p && p['search']) {
          if (this.entries.value) {
            this.search = p['search'];
            this.query();
          } else {
            this.queryParamsearch = p['search'];
          }
        }
    })
  }

  private detectChanges() {
    if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }
  }

  private initScrollBox() {
    if (this.scrollbox && this.scrollbox.SimpleBar) {
      this.scrollbox.SimpleBar.getScrollElement().addEventListener('scroll', (e) => {
        const scrollheight = e.target.scrollTop + e.target.offsetHeight;

        const pageheight = e.target.offsetHeight - 50;

        const load_border = pageheight * (this.page_number - 1) + (pageheight * 0.3);

        if (scrollheight > load_border) {
          if (!this.loading.value) {
            this.nextPage();
          } else {
            this.nextAfterNextPage = true;
          }
        } else {

        }
      });
      this.detectHeight(null);
    } else {
      AppAsyncTimeout.setTimeout(() => {
        this.initScrollBox();
      }, 200);
    }
  }

  private loadSettings() {
    this.settsvc.getUserSettingValue('ShopViewArticleFontSize').subscribe(s => {
      switch (s) {
        case 1:
          this.fsize = 'font-size-1';
          break;
        case 2:
          this.fsize = 'font-size-2';
          break;
        case 3:
          this.fsize = 'font-size-3';
          break;
        case 4:
          this.fsize = 'font-size-4';
          break;
        case 5:
          this.fsize = 'font-size-5';
          break;
        default:
          this.fsize = 'font-size-1';
      }
    });
  }

  private init() {
    //this.loader.start('ShopViewComponent_1', 'Shop wird geladen!');

    this.vsvc.getBySlug(this.slug).subscribe(v => {
      if (this.lksvc.current_differential_lieferkunde.value && !v.settings.different_lieferkunde_mode) {
        this.msg.error("Im Lieferung für Modus nicht verfügbar!");
        this.nbsvc.triggerBack();
      }
      this.nbsvc.setFontColor(v.settings.font_color);
      this.nbsvc.setBGColor(v.settings.bg_color);
      this.nbsvc.setBGLinkColor(v.settings.bg_link_color);
      this.nbsvc.setInversedLogo(v.settings.logo_inversed);
      this.nbsvc.setLabel(v.getLabel());
      this.view.next(v);
      this.tsvc.setTitle('Shop - ' + v.getLabel());
      AppAsyncTimeout.setTimeout(() => {
        this.initScrollBox();
      }, 300);
      //this.loader.stop('ShopViewComponent_1');
      this.loadView((had_query) => {
        if (this.queryParamsearch) {
          this.search = this.queryParamsearch;
          this.active_filters = [];
          this.queryParamsearch = undefined;
        }
        if (this.view.value.settings.allow_empty_search || had_query) {
          this.query();
        }
      });

    });
  }


}
