import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CustomerSurvey} from '../models/customer-survey';
import {CustomerSurveyAnswer} from '../models/customer-survey-answer';
import {CustomerSurveyStat} from '../models/customer-survey-stat';

@Injectable({providedIn: 'root'})
export class AppCustomerSurveyService {
  constructor(private http: HttpClient) {
  }

  getMine(): Observable<CustomerSurvey> {
    return this.http.get<CustomerSurvey>(
      '/customer_survey'
    );
  }

  postpone1(s: CustomerSurvey): Observable<boolean> {
    return this.http.get<boolean>(
      '/customer_survey/' + s.id + '/postpone1'
    );
  }

  answer(s: CustomerSurvey, a: CustomerSurveyAnswer): Observable<boolean> {
    return this.http.post<boolean>(
      '/customer_survey/' + s.id + '/answer',
      {
        answer: a
      }
    );
  }


  postpone2(s: CustomerSurvey): Observable<boolean> {
    return this.http.get<boolean>(
      '/customer_survey/' + s.id + '/postpone2'
    );
  }

  decline(s: CustomerSurvey): Observable<boolean> {
    return this.http.get<boolean>(
      '/customer_survey/' + s.id + '/decline'
    );
  }

  adminGetAll(): Observable<CustomerSurvey[]> {
    return this.http.get<CustomerSurvey[]>(
      '/admin/customer_survey'
    );
  }

  adminGetById(id): Observable<CustomerSurvey> {
    return this.http.get<CustomerSurvey>(
      '/admin/customer_survey/' + id
    );
  }

  adminGetStatById(id): Observable<CustomerSurveyStat> {
    return this.http.get<CustomerSurveyStat>(
      '/admin/customer_survey/' + id + '/stat'
    );
  }

  adminDeleteById(id): Observable<CustomerSurvey> {
    return this.http.get<CustomerSurvey>(
      '/admin/customer_survey/' + id + '/delete'
    );
  }

  adminSet(survey: CustomerSurvey): Observable<CustomerSurvey> {
    return this.http.post<CustomerSurvey>(
      '/admin/customer_survey/' + survey.id,
      {
        survey: survey
      }
    );
  }
}
