import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {Observable} from 'rxjs';
import {Artikel} from '../models/artikel';
import {AppEventService} from './app.event.service';
import {AppLieferkundeService} from './app.lieferkunde.service';
import {Lieferkunde} from '../models/lieferkunde';
import {AppPageloaderService} from './app.pageloader.service';
import {Bestellung} from '../models/bestellung';
import {BestellungEntry} from '../models/bestellung-entry';
import {Subbestellung} from '../models/subbestellung';
import {BestellungPreisUndMwstSatz} from '../models/bestellung-preis-und-mwst-satz';
import {map} from 'rxjs/operators';
import setPrototypeOf = Reflect.setPrototypeOf;
import {PaginatedResult} from "../models/paginated-result";

@Injectable({providedIn: 'root'})
export class AppBestellungService {
  lieferkunde: Lieferkunde;

  constructor(private http: HttpClient, private cfg: AppConfigService, private esvc: AppEventService,
              private lksvc: AppLieferkundeService, private loader: AppPageloaderService) {
    this.lksvc.current_lieferkunde$.subscribe(lk => {
      this.lieferkunde = lk;
    });
  }

  getList(page, limit, search, with_details = false) {
    return this.http.post<PaginatedResult<Bestellung>>(
      this.cfg.buildUrl('/bestellung'), {
        page: page,
        limit: limit,
        search: search,
        with_details: with_details
      }
    ).pipe(map(bs => {
      bs.data.forEach(b => {
        this.protoBestellung(b);
      });
      return bs;
    }));
  }

  getBestellungById(id: number): Observable<Bestellung> {
    return this.http.get<Bestellung>(
      this.cfg.buildUrl('/bestellung/' + id)
    ).pipe(map(b => {
      this.protoBestellung(b);
      return b;
    }));
  }

  public protoBestellung(best: Bestellung) {
    if (best) {
      setPrototypeOf(best, Bestellung.prototype);
      best.view_collapsed = true;

      if (best.subbestellungen) {
        best.subbestellungen.forEach(e => {
          setPrototypeOf(e, Subbestellung.prototype);

          if (e.positionen) {
            e.positionen.forEach(p => {
              setPrototypeOf(p, BestellungEntry.prototype);

              if (p.artikel) {
                setPrototypeOf(p.artikel, Artikel.prototype);
              }
            });
          }

          if (e.summen) {
            e.summen.forEach(s => {
              setPrototypeOf(s, BestellungPreisUndMwstSatz.prototype);
            });
          }


        });
      }
    }
  }
}
