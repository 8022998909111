<dashboard-widget>
  <div icon>
    <svg-icon>news</svg-icon>
  </div>
  <div header1 matTooltip="News">
    News
  </div>
  <div header2>
    <mat-spinner *ngIf="(loading1|async) || (loading2|async)" [diameter]="20"></mat-spinner>
  </div>
  <div header3>
    <mat-form-field style="width: 80px">
      <mat-select (selectionChange)="changeLimit()" [(value)]="limit" placeholder="Maximale Anzahl">
        <mat-option *ngFor="let l of limits" [value]="l">{{l}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div content>
    <div *ngIf="(tags|async) && ((tags|async)?.length > 1 || getTags().length > 0)" class="filter">
        <span (click)="toggleTag(tag)" *ngFor="let tag of tags|async"
                  [class.active]="selected_tags[tag.id] == true" [style.color]="tag_colors[tag.id]"
                  style="cursor:pointer;">{{tag.name}}</span>
    </div>
    <div *ngFor="let e of (data|async)?.data" class="newsboxentry">
      <app-dashboard-widgets-news-entry [entry]="e" [tag_colors]="tag_colors"
                                        [tags]="tags|async"></app-dashboard-widgets-news-entry>

    </div>
    <div *ngIf="!(loading1 || loading2) && (!(data|async) || (data|async)?.count == 0)">
      <h3>Derzeit nichts Neues!</h3>
    </div>
  </div>
</dashboard-widget>
