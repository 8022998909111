<div class="ajax-warenkorb">
  <div class="paddedcontent">
    <h3>
      <svg-icon>warenkorb</svg-icon> {{'Warenkorb ' + (warenkorb|async)?.label}}
    </h3>
  </div>
  <div style="position: relative">
    <warenkorb-box [ajax_mode]="true" [warenkorb]="warenkorb|async"></warenkorb-box>
  </div>
</div>
