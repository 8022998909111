import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA} from '@angular/material/legacy-snack-bar';
import {Message} from '../../models/message';

@Component({
  styleUrls: ['app.snackbar-message.component.scss'],
  templateUrl: 'app.snackbar-message.component.html'
})
export class AppSnackbarMessageComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public msg: Message) {
  }
}
