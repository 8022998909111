<h1 mat-dialog-title>
  Nicht alle Produkte sind lieferbar!
</h1>
<div mat-dialog-content style="min-height: 100px">
  <table class="table table-striped table-hover">
    <thead>
      <tr>
        <td>Position</td>
        <td>Info</td>
        <td>Nachliefern?</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let m of messages; let idx = index;">
        <td>
          <strong [innerHTML]="m.title|raw"></strong>
        </td>
        <td style="vertical-align: top !important;">
          <div [innerHTML]="m.msg|raw"></div>
        </td>
        <td style="vertical-align: top !important; text-align: center">
          <mat-checkbox [(ngModel)]="nachliefernChecked[idx]" (change)="changeNachliefernChecked()"></mat-checkbox>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div mat-dialog-actions style="text-align: center">
  <button mat-raised-button (click)="doClose()" color="primary">
    Laut Auswahl ({{nachliefernCheckedCounter}}) nachliefern
  </button>

  <button mat-raised-button (click)="selectAll()" color="warn">
    Alle ({{messages.length}}) nachliefern
  </button>

  <button mat-raised-button (click)="selectNone()">
    Nichts nachliefern
  </button>
</div>

