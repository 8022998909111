import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppEventService} from './app.event.service';
import {Router} from '@angular/router';
import {User} from '../models/user';
import {AppConfigService} from './app.config.service';
import {AppBootloaderService} from './app.bootloader.service';
import {PagedResult} from '../models/pagedresult';
import {AppMessageService} from './app.message.service';
import {Usersession} from '../models/usersession';
import {AppDialogsService} from './app.dialogs.service';
import {map} from 'rxjs/operators';
import {RegisterSettings} from '../models/register-settings';
import {UserExistsResponse} from '../models/user-exists-response';
import {RegisterRequest} from '../models/register-request';
import {OnlineUserListEntry} from '../modules/admin/model/online-user-list-entry';
import {UserIp} from '../models/user-ip';
import {AppLieferkundeService} from "./app.lieferkunde.service";
import {AppPageloaderService} from "./app.pageloader.service";
import {Kundenart} from "../models/kundenart";
import {AppLogService} from "./app.log.service";
import {AppAsyncTimeout} from "../helpers/app.asyncTimeout";

declare let window: any;


@Injectable({providedIn: 'root'})
export class AppUserService {
  url_get_user = '/user/get';
  url_login = '/user/login';
  url_logout = '/user/logout';
  url_reset1 = '/reset-password1';
  url_reset2 = '/reset-password2';
  url_change = '/change-password';
  url_validate_ip = '/validate-ip';

  local_sess_entries = [];

  user = new BehaviorSubject<User>(new User());
  user$ = this.user.asObservable();
  isLoggedin$ = new BehaviorSubject<boolean>(false);

  lkWarmupDone = new BehaviorSubject<boolean>(false);

  isLoggedin = false;
  is_loading_user = false;

  constructor(private http: HttpClient, private cfg: AppConfigService, private eventsvc: AppEventService,
              private router: Router, private bootloader: AppBootloaderService, private msg: AppMessageService,
              private lksvc: AppLieferkundeService, private loader: AppPageloaderService, private logger: AppLogService,
              private dsvc: AppDialogsService) {
    this.user$.subscribe(user => {
      this.isLoggedin = (user instanceof User && user.id !== undefined);
      this.isLoggedin$.next(this.isLoggedin);

      if (this.isLoggedin && this.user.value.hasRole('Vertreter')) {
        this.cfg.loadVertreterConfig();
      }
    });

    if (this.bootloader.loggedin) {
      this.user.next(this.bootloader.user);
      this.eventsvc.addGuiEvent('user_loaded');
      if (this.bootloader.user.vertreter) {
        this.eventsvc.addGuiEvent('vertreter_loaded');
      }
      this.lksvc.warmUp().subscribe(s => {
        this.lkWarmupDone.next(true);
      })
    } else {
      this.user.next(new User());
    }
    this.eventsvc.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\User\\DoLogoffUserEvent') {
        let seconds = 10;

        const message = this.dsvc.info(
          'info',
          'Remote Abmeldung',
          'Sie werden in ' + seconds + ' Sekunden automatisch abgemeldet!',
          () => {

          }
        );
        const countdown = setInterval(() => {
          seconds -= 1;
          if (message.componentInstance) {
            message.componentInstance.text = 'Sie werden in ' + seconds + ' Sekunden automatisch abgemeldet!';
          }
          if (seconds <= 0) {
            clearInterval(countdown);
            if (message.componentInstance) {
              message.componentInstance.text = 'Abmeldung läuft...';
            }
            AppAsyncTimeout.setTimeout(() => {
              message.close();
              this.logout().subscribe(() => {
              });
            }, 2000);
          }
        }, 1000);

      } else if (e.name == 'App\\Events\\User\\NotLoggedinEvent') {
        if (this.user.value.id) {
          this.logout().subscribe(s => {
            setTimeout(() => {
              router.navigateByUrl('/login');
            }, 2000);
          });

        }
      } else if (
        (e.name == 'App\\Events\\SettingsEngine\\SettingsChangedEvent' && e.data.id == 'DomainVertreterConfig')
      ) {
        this.cfg.loadVertreterConfig();
      }
    });
    this.isLoggedin$.subscribe(s => {
      if (s) {
        this.eventsvc.setLoggedin();
      } else {
        this.eventsvc.setNotLoggedin();

        const pathname = window.location.pathname;

        //IF not loggedin redirect if does not machtes
        if (
          pathname !== '/register' &&
          !pathname.match(/^\/reset-password.*$/) &&
          !pathname.match(/^\/validate-ip.*$/) &&
          !pathname.match(/^\/docs\/.*$/)
        ) {
          try {
            localStorage.setItem('target_before_login_redirect', window.location.pathname);
          } catch (e) {
            localStorage.clear();
            localStorage.setItem('target_before_login_redirect', window.location.pathname);
          }
          this.router.navigateByUrl('/login');
        }
      }
    });

    if (localStorage.getItem('AppUserService_local_sess_entries')) {
      this.local_sess_entries = JSON.parse(localStorage.getItem('AppUserService_local_sess_entries'));
    }
  }

  onAfterLogin() {
    this.lksvc.warmUp().subscribe(s => {
      this.lkWarmupDone.next(true);
    });
  }

  onAfterLogout() {
    this.lkWarmupDone.next(false);
    this.clearLocalStorage();
  }

  addDevice(subscription) {
    return this.http.post<any>(
      '/user/addDevice',
      {
        subscription: subscription,
      }
    );
  }

  resetPassword1(email: string): Observable<boolean> {
    return this.http.post<boolean>(
      this.url_reset1,
      {
        email: email
      }
    );
  }

  resetPassword2(token: string, password: string): Observable<boolean> {
    return this.http.post<boolean>(
      this.url_reset2,
      {
        token: token,
        password: password
      }
    );
  }

  getUserIps(): Observable<UserIp[]> {
    return this.http.get<UserIp[]>(
      '/user_ips'
    );
  }

  deleteUserIp(uip: UserIp): Observable<boolean> {
    return this.http.get<boolean>(
      '/user_ips/' + uip.id + '/delete'
    );
  }

  getIPvalidateByToken(token: string): Observable<string | boolean> {
    return this.http.get<string | boolean>(
      this.url_validate_ip,
      {
        params: {
          token: token
        }
      }
    );
  }

  validateIP(token: string): Observable<boolean> {
    return this.http.post<boolean>(
      this.url_validate_ip,
      {
        token: token
      }
    );
  }

  changePassword(password: string): Observable<boolean> {
    return this.http.post<boolean>(
      this.url_change,
      {
        password: password
      }
    );
  }

  testUserExists(email: string): Observable<UserExistsResponse> {
    return this.http.post<UserExistsResponse>(
      '/user/exists',
      {
        email: email
      }
    );
  }

  getRegisterSettings(): Observable<RegisterSettings> {
    return this.http.get<RegisterSettings>(
      '/register'
    );
  }

  registerUser(data: RegisterRequest): Observable<number> {
    return this.http.post<number>(
      '/register',
      {
        data: data
      }
    );
  }

  getAllRoles(): Observable<string[]> {
    return this.http.get<string[]>(
      '/user/all_roles'
    );
  }

  getAllKundenarten(): Observable<Kundenart[]> {
    return this.http.get<Kundenart[]>(
      '/user/all_kundenarten'
    );
  }

  addUserLocalSessionEntry(key: string) {
    if (!this.local_sess_entries.includes(key)) {
      this.local_sess_entries.push(key);
      localStorage.setItem('AppUserService_local_sess_entries', JSON.stringify(this.local_sess_entries));
    }
  }

  clearUserLocalSessionStorage() {
    this.local_sess_entries.forEach(k => {
      localStorage.removeItem(k);
    });
    localStorage.removeItem('AppUserService_local_sess_entries');
  }

  clearLocalStorage() {
    /*
    localStorage.removeItem('customer_statistics_current_year');
    localStorage.removeItem('customer_statistics_current_month');
    localStorage.removeItem('customer_statistics_current_tab');
    */
    this.eventsvc.addGuiEvent('user.logoff');
    this.clearUserLocalSessionStorage();
  }

  logoffUsers(userids: number[]): Observable<boolean> {
    return this.http.post<boolean>(
      '/admin/users/dologoff',
      {
        'user_ids': userids
      }
    );
  }

  login(username: string, password: string): Observable<User> {
    return this.http.post<User>(
      this.url_login,
      {
        username: username,
        password: password
      }
    ).pipe(map(state => {
      this.clearLocalStorage();
      if (state) {
        this.loadUser(() => {
          this.onAfterLogin();
        });
      }
      this.cfg.loadDomainConfig(() => {

      });
      return state;
    }));
  }

  logout() {
    this.isLoggedin = false;
    return this.http.get<any>(
      this.url_logout
    ).pipe(map(state => {
      if (state) {
        this.user.next(new User());
        this.onAfterLogout();
        this.eventsvc.addGuiEvent('user_loggedout');
      }
    }));
  }

  loadUser(exec_after_load=null) {
    if (this.is_loading_user == false) {
      this.is_loading_user = true;

      this.http.get<User>(this.url_get_user)
        .pipe(map((user) => {
          return <User>user;
        }))
        .subscribe({
          next: (user: User) => {
            Object.setPrototypeOf(user, User.prototype);
            this.user.next(user);
            this.is_loading_user = false;
            this.eventsvc.addGuiEvent('user_loaded');

            if (user.vertreter) {
              this.eventsvc.addGuiEvent('vertreter_loaded');
            }

            if (exec_after_load) {
              exec_after_load();
            }

          }, error: (e) => {
            if (e.status == 401) {
              localStorage.setItem('target_before_login_redirect', window.location.pathname);
              this.router.navigateByUrl('/login');
            }
          }
        });
    }
  }

  hasRoles(roles: string[]): Observable<boolean> {
    return this.user$.pipe(map(u => {
      if (u) {
        let state = u.hasRoles(roles);

        this.logger.consoleDebug("User ", u, " has roles", roles, 'state', state);
        return state;

      } else {
        this.logger.consoleDebug("User ", u, " has roles", roles, 'state', 'false - no user');
        return false;
      }
    }));
  }

  hasAllRoles(roles: string[]): Observable<boolean> {
    return this.user$.pipe(map(u => {
      if (u) {
        let state = u.hasAllRoles(roles);

        this.logger.consoleDebug("User ", u, " has AllRoles", roles, 'state', state);
        return state;

      } else {
        this.logger.consoleDebug("User ", u, " has AllRoles", roles, 'state', 'false - no user');
        return false;
      }
    }));
  }

  isCrmAdmin(): Observable<boolean> {
    return this.user$.pipe(map(u => {
      return u.hasRole('CRM_ADMIN');
    }));
  }

  getRoles(): Observable<string[]> {
    return this.http.get<string[]>(
      '/user/roles'
    );
  }

  getAll(with_roles: boolean = false): Observable<User[]> {
    return this.http.post<User[]>(
      '/user/all',
      {
        with_roles: with_roles
      }
    ).pipe(map(us => {
      us.forEach(u => {
        Object.setPrototypeOf(u, User.prototype);
      });

      return us;
    }));
  }

  deleteUser(user: User): Observable<boolean> {
    return this.http.get<boolean>(
      '/admin/user/'+user.id+'/delete',
    );
  }

  search(page: number, limit: number, search: string): Observable<PagedResult<User>> {
    return this.http.post<PagedResult<User>>(
      '/admin/users/search',
      {
        search: search,
        limit: limit,
        page: page
      }
    ).pipe(map(r => {
      Object.setPrototypeOf(r, PagedResult.prototype);

      r.data.forEach(d => {
        Object.setPrototypeOf(d, User.prototype);
      });

      return r;
    }));
  }


  impersonate(user: User) {

    this.eventsvc.inpersonate();
    this.http.post(
      '/admin/impersonate',
      {
        _impersonate_user_name: user.email
      }
    ).subscribe(s => {
      this.eventsvc.addGuiEvent('impersonate_start');
      this.clearUserLocalSessionStorage();
      this.loadUser(() => {
        this.msg.info('Impersonate erfolgreich!');

        setTimeout(() => {
          this.eventsvc.addGuiEvent('impersonate_end');
          this.router.navigateByUrl('/lieferkunden');

        }, 200);
      });
    });
  }


  depersonate() {
    this.eventsvc.depersonate();
    this.http.post(
      '/admin/depersonate',
      {
        _impersonate_user_name: '_exit'
      }
    ).subscribe(s => {
      this.eventsvc.addGuiEvent('depersonate_start');
      this.loadUser(() => {
        setTimeout(() => {
          this.eventsvc.addGuiEvent('depersonate_end');
          this.router.navigateByUrl('/lieferkunden');

        }, 200);
      });

      this.msg.info('Depersonate erfolgreich!');
    });
  }

  getOnlineUsers(): Observable<OnlineUserListEntry[]> {
    return this.http.get<OnlineUserListEntry[]>(
      '/admin/user/online'
    ).pipe(map(entries => {
      if (entries) {
        entries.forEach(entry => {
          if (entry.sessions) {
            entry.sessions.forEach(s => {
              if (s) {
                Object.setPrototypeOf(s, Usersession.prototype);
                if (s.user) {
                  Object.setPrototypeOf(s.user, User.prototype);
                }
                if (s.inpersonate_user) {
                  Object.setPrototypeOf(s.inpersonate_user, User.prototype);
                }
              }
            });
          }
        });
      }
      return entries;
    }));
  }
}
