<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
  <div [innerHTML]="text|raw"></div>

  <mat-form-field (keydown.enter)="fieldEnterKeyDown(f)" *ngFor="let f of fields; let last = last">
    <input *ngIf="!f.is_textarea && !f.choices" [(ngModel)]="f.value" matInput placeholder="{{f.label}}">
    <mat-select *ngIf="!f.is_textarea && f.choices" [(ngModel)]="f.value" placeholder="{{f.label}}">
      <mat-option *ngFor="let opt of f.choices" [value]="opt.id">
        {{opt.label}}
      </mat-option>
    </mat-select>

    <textarea *ngIf="f.is_textarea" [(ngModel)]="f.value" [mat-autosize]="true" [matAutosizeMinRows]="5"
              matInput
              placeholder="{{f.label}}"></textarea>
  </mat-form-field>

</div>
<div align="center" mat-dialog-actions>
  <button (click)="click(button)" *ngFor="let button of buttons" class="mat-raised-button {{button.class}}"
          mat-raised-button>
    {{button.label}}
  </button>
</div>
