import {Formfield} from './formfield';
import {Formbutton} from './formbutton';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {FormComponentDialog} from './form.component';
import {Formchoice} from './formchoice';

export class Formbuilder {
  svc: MatDialog;
  title = '';
  text = '';
  fields: Formfield[] = [];
  buttons: Formbutton[] = [];

  constructor(svc: MatDialog) {
    this.svc = svc;
  }

  setTitle(title: string) {
    this.title = title;
    return this;
  }

  setText(text: string) {
    this.text = text;
    return this;
  }

  addButton(id: string, label: string, cssclass: string = null) {
    const b = new Formbutton();
    b.id = id;
    b.label = label;
    if (cssclass) {
      b.class = cssclass;
    }

    this.buttons.push(b);

    return this;
  }

  addField(id: string, label: string, is_textarea: boolean = false, choices: Formchoice[] = null, value = null,
           keydown_enter_button_press_id = null) {
    const f = new Formfield();
    f.id = id;
    f.label = label;
    f.is_textarea = is_textarea;
    f.choices = choices;
    f.value = value;
    f.keydown_enter_button_press_id = keydown_enter_button_press_id;

    this.fields.push(f);

    return this;
  }

  show(fkt_after_close = null) {
    const dialog = this.svc.open(FormComponentDialog, {
      data: {
        title: this.title,
        text: this.text,
        fields: this.fields,
        buttons: this.buttons,
      }
    });
    dialog.afterClosed().subscribe(result => {
      if (fkt_after_close) {
        fkt_after_close(result);
      }
    });
  }
}

