import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DashboardWidgetService} from '../../services/dashboard.widget.service';
import {DashboardWidgetInterface} from "../../interfaces/dashboard.widget.interface";


@Component({
  templateUrl: 'dashboard.dialogs.add-widget.component.html',
  styleUrls: ['dashboard.dialogs.add-widget.component.scss']
})
export class DashboardDialogsAddWidgetComponent implements OnInit, OnDestroy {
  widgets: DashboardWidgetInterface[];

  constructor(public dialogRef: MatDialogRef<DashboardDialogsAddWidgetComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any[], private svc: DashboardWidgetService) {
  }

  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.widgets = this.data;
  }

  send(widget) {
    this.dialogRef.close(widget);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
