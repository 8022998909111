import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {DashboardViewComponent} from './components/view/dashboard.view.component';
import {DashboardWidgetsNewsComponent} from './widgets/news/dashboard.widgets.news.component';
import {DashboardWidgetsNewsEntryComponent} from './widgets/news/dashboard.widgets.news.entry.component';
import {DashboardWidgetsMerklistenComponent} from './widgets/merklisten/dashboard.widgets.merklisten.component';
import {DashboardWidgetsWarenkoerbeComponent} from './widgets/warenkoerbe/dashboard.widgets.warenkoerbe.component';
import {
  DashboardWidgetsImpWarenkorbTickerComponent
} from './widgets/imp-warenkorb-ticker/dashboard.widgets.imp-warenkorb-ticker.component';
import {
  DashboardWidgetsCurrentWarenkorbComponent
} from './widgets/current-warenkorb/dashboard.widgets.current-warenkorb.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {GridsterModule} from 'angular-gridster2';
import {DashboardDialogsAddWidgetComponent} from './dialogs/add-widget/dashboard.dialogs.add-widget.component';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {DashboardWidgetComponent} from './components/widget/dashboard.widget.component';
import {ShopCompontentsModule} from '../shop/shop.compontents.module';
import {DashboardWidgetsShopViewsComponent} from "./widgets/shop-views/dashboard.widgets.shop-views.component";
import {
  DashboardWidgetsFavouriteMenuButtonsComponent
} from "./widgets/favourite-menu-buttons/dashboard.widgets.favourite-menu-buttons.component";
import {MainMenuPartsModule} from "../main-menu/main-menu-parts.module";
import {RouterModule} from "@angular/router";

@NgModule({
  declarations: [
    DashboardViewComponent,
    DashboardWidgetsNewsComponent,
    DashboardWidgetsNewsEntryComponent,
    DashboardWidgetsMerklistenComponent,
    DashboardWidgetsWarenkoerbeComponent,
    DashboardWidgetsImpWarenkorbTickerComponent,
    DashboardWidgetsCurrentWarenkorbComponent,
    DashboardDialogsAddWidgetComponent,
    DashboardWidgetComponent,
    DashboardWidgetsShopViewsComponent,
    DashboardWidgetsFavouriteMenuButtonsComponent
  ],
  exports: [
    DashboardViewComponent
  ],
  imports: [
    ShopCompontentsModule,
    GridsterModule,
    RouterModule.forChild([]),
    SharedModule,
    MatGridListModule,
    DragDropModule,
    MatListModule,
    MainMenuPartsModule,
  ],
  providers: []
})
export class DashboardPartsModule {
}
