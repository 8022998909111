import {BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, Injectable, LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {AppComponent} from './app.component';
import {AppLoginComponent} from './components/login/app.login.component';
import {AppUserLogoutComponent} from './components/logout/app.user.logout.component';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppNotificationComponent} from './components/notification/app.notification.component';
import {AppGuardsAuthguard} from './guards/app.guards.authguard';
import {AppBootloaderService} from './services/app.bootloader.service';
import {AppHelpersHttprequestmanipulator} from './helpers/app.helpers.httprequestmanipulator';
import {SharedModule} from './modules/shared/shared.module';
import {AppNotificationEntryComponent} from './components/notification/app.notification.entry.component';
import {AppNotificationListComponent} from './components/notification/app.notification.list.component';
import {AppPageLoaderComponent} from './components/page-loader/app.page-loader.component';
import {AppUserDepersonateComponent} from './components/depersonate/app.user.depersonate.component';
import {AppHelpersErrorHandler} from './helpers/app.helpers.error-handler';
import {AppSettingsComponent} from './components/settings/app.settings.component';
import {AppWalkthroughComponent} from './components/walkthrough/app.walkthrough.component';
import {AppTutorialsComponent} from './components/tutorials/app.tutorials.component';
import {AppTutorialStepComponent} from './components/tutorial-step/app.tutorial-step.component';
import {AppLayoutsLoaderComponent} from './components/layouts/app.layouts.loader.component';
import {AppLayoutsLoggedinComponent} from './components/layouts/app.layouts.loggedin.component';
import {AppLayoutsLoginComponent} from './components/layouts/app.layouts.login.component';
import {AppDialogsSendmessageComponent} from './dialogs/sendmessage/app.dialogs.sendmessage.component';
import {FormComponentDialog} from './dialogs/form/form.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {AppDialogsCustomdialogComponent} from './dialogs/customdialog/app.dialogs.customdialog.component';
import {UserMenuComponent} from './components/user-menu/user-menu.component';
import {AppLieferkundeSelectorComponent} from './components/lieferkunde-selector/app.lieferkunde-selector.component';
import {AppGuardsHasLieferkunde} from './guards/app.guards.haslieferkunde';
import {MainMenuButtonComponent} from './components/main-menu-button/main-menu-button.component';
import {AppWarenkorbMenuComponent} from './components/warenkorb-menu/app.warenkorb-menu.component';
import {AppRegisterComponent} from './components/register/app.register.component';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {AppCustomerSettingsComponent} from './components/customer-settings/app.customer-settings.component';
import {AppNavBarComponent} from './components/nav-bar/app.nav-bar.component';
import * as Hammer from 'hammerjs';
import {AppFooterComponent} from './components/footer/app.footer.component';
import {AppResetPasswordComponent} from './components/reset-password/app.reset-password.component';
import {AppChangePasswordComponent} from './components/change-password/app.change-password.component';
import {AppSnackbarMessageComponent} from './components/snackbar-message/app.snackbar-message.component';
import {MatomoModule} from './modules/matamo/matomo-module';
import {
  AppCustomerConditionCheckerComponent
} from './components/customer-condition-checker/app.customer-condition-checker.component';
import {AppGuardsCondition} from './guards/app.guards.condition';
import {
  AppCustomerSurveyWatcherComponent
} from './components/customer-survey-watcher/app.customer-survey-watcher.component';
import {AppDialogsCustomerSurveyComponent} from './dialogs/customer-survey/app.dialogs.customer-survey.component';
import {AppDifferntialViewGuard} from './guards/app.differntial-view.guard';
import * as Sentry from '@sentry/angular-ivy';
import {Router} from '@angular/router';
import {AppGuardsFeatureguard} from './guards/app.guards.featureguard';
import {AppDialogsPrivacyComponent} from './dialogs/privacy/app.dialogs.privacy.component';
import {AppValidateIpComponent} from "./components/validate-ip/app.validate-ip.component";
import {AppGlobalSearchBoxComponent} from "./components/global-search-box/app.global-search-box.component";
import {AppUpdateIconComponent} from "./components/update-icon/app.update-icon.component";
import {MainMenuPartsModule} from "./modules/main-menu/main-menu-parts.module";
import {DashboardPartsModule} from "./modules/dashboard/dashboard.parts.module";
import {
  AppDomainSuggestionsComponent,
} from "./components/domain-suggestions/app.domain-suggestions.component";
import {
  AppDialogsDomainSuggestionsComponent
} from "./dialogs/domain-suggestions/app.dialogs.domain-suggestions.component";

registerLocaleData(localeDe, 'de');

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    'swipe': {direction: Hammer.DIRECTION_ALL}
  };
}

export function bootUp(loader: AppBootloaderService): Function {
  return () => loader.boot();
}

@NgModule({
  declarations: [
    AppComponent,
    AppLoginComponent,
    AppUserLogoutComponent,
    AppLayoutsLoaderComponent,
    AppLayoutsLoggedinComponent,
    AppLayoutsLoginComponent,
    AppNotificationComponent,
    AppNotificationEntryComponent,
    AppNotificationListComponent,
    AppPageLoaderComponent,
    AppUserDepersonateComponent,
    AppSettingsComponent,
    AppWalkthroughComponent,
    AppTutorialsComponent,
    AppTutorialStepComponent,
    AppDialogsSendmessageComponent,
    FormComponentDialog,
    AppDialogsCustomdialogComponent,
    UserMenuComponent,
    AppLieferkundeSelectorComponent,
    MainMenuButtonComponent,
    AppWarenkorbMenuComponent,
    AppRegisterComponent,
    AppCustomerSettingsComponent,
    AppNavBarComponent,
    AppFooterComponent,
    AppResetPasswordComponent,
    AppChangePasswordComponent,
    AppSnackbarMessageComponent,
    AppCustomerConditionCheckerComponent,
    AppCustomerSurveyWatcherComponent,
    AppDialogsCustomerSurveyComponent,
    AppDialogsPrivacyComponent,
    AppValidateIpComponent,
    AppGlobalSearchBoxComponent,
    AppUpdateIconComponent,
    AppDomainSuggestionsComponent,
    AppDialogsDomainSuggestionsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    MatomoModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatRadioModule,
    MainMenuPartsModule,
    DashboardPartsModule,
  ],
  providers: [
    AppGuardsAuthguard,
    AppGuardsFeatureguard,
    AppGuardsHasLieferkunde,
    AppGuardsCondition,
    AppDifferntialViewGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHelpersHttprequestmanipulator,
      multi: true
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useClass: AppHelpersErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: bootUp,
      deps: [AppBootloaderService, Sentry.TraceService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig}
  ],
  bootstrap: [AppComponent],
  exports: [
    MainMenuButtonComponent,
    AppNavBarComponent,
  ]
})
export class AppModule {
}

