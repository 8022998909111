<div *ngIf="article">
  <button class="close" mat-raised-button [mat-dialog-close]="hasChange">X</button>
  <h1 mat-dialog-title>
    <svg-icon>sonderpreis</svg-icon>
    Kundensonderpreise bearbeiten
  </h1>
  <div mat-dialog-content style="min-height: 200px">
    <h3 style="margin-bottom: 20px">
      <view-field-value-render
        [nowrap]="false"
        [artikel]="article"
        [fieldname]="'pzn'"></view-field-value-render>
      -
      <view-field-value-render
      [nowrap]="false"
      [artikel]="article"
      [fieldname]="'bezeichnung'"></view-field-value-render>
    </h3>
    <div>
      <mat-checkbox [(ngModel)]="history" (change)="load()">Vergangenheitsdaten anzeigen</mat-checkbox>
    </div>
    <div *ngIf="!(data|async); else ifelse">
      <div style="text-align: center">
        <div style="display: inline-block">
          <div>
            <mat-spinner></mat-spinner>
          </div>
          <div>
            Lade...
          </div>
        </div>
      </div>

    </div>

    <ng-template #ifelse>
      <table class="table table-striped table-hover">
        <tr>
          <th></th>
          <th>Von</th>
          <th>Bis</th>
          <th>Anlage</th>
          <th>Preis</th>
          <th></th>
        </tr>
        <tr *ngFor="let sp of (data|async)?.SonderpreiseKunde">
          <td>
            <div style="background-color: green; border-radius: 50%; height: 10px; width: 10px; cursor: help" *ngIf="sp.active" matTooltip="Aktiv"></div>
            <div style="background-color: lightblue; border-radius: 50%; height: 10px; width: 10px; cursor: help" *ngIf="sp.inFuture" matTooltip="In Zukunft"></div>
            <div style="background-color: lightgrey; border-radius: 50%; height: 10px; width: 10px; cursor: help" *ngIf="!sp.active && !sp.inFuture" matTooltip="Abgelaufen"></div>
          </td>
          <td>
            {{ sp.GueltigVon }}
          </td>
          <td>
            {{ sp.GueltigBis }}
          </td>
          <td>
            {{ sp.AnlageDatum }} - {{ sp.PreisErstellungBenutzer }}
            <span *ngIf="sp.PreisText">
              ({{ sp.PreisText }})
            </span>
          </td>
          <td>
            {{ sp[(data|async)?.VerrechnungsPreiscodeKunde]|NiceNumber }}
          </td>
          <td>
            <button mat-icon-button matTooltip="Löschen" (click)="delete(sp)" [disabled]="!sp.active && !sp.inFuture">
              <svg-icon>loeschen</svg-icon>
            </button>
          </td>
        </tr>
        <tr *ngIf="!((data|async)?.SonderpreiseKunde) || (data|async)?.SonderpreiseKunde.length == 0">
          <td colspan="6" style="text-align: center">
            - Keine Sonderpreise hinterlegt -
          </td>
        </tr>
      </table>

      <div *ngIf="!newEntry" style="text-align: center; padding: 5px;">
          <button mat-raised-button (click)="startNew()" color="primary">
            <svg-icon>plus</svg-icon>
            Neuen Preis anlegen
          </button>
      </div>

      <div *ngIf="newEntry" style="margin-bottom: 5px; margin-top: 15px">
        <div class="mat-elevation-z1">
          <mat-toolbar>Neuer Sonderpreis</mat-toolbar>
          <div style="padding: 5px">
            <table class="table">
              <tr>
                <td style="border-top:0; height: 47px">
                  <strong>Gueltig von</strong>
                </td>
                <td style="border-top:0">
                  {{ newEntry.GueltigVon|date:'dd.MM.yyyy' }}
                </td>
                <td style="border-top:0">
                  <button (click)="dp1.open()" mat-raised-button>
                    <svg-icon>kalender</svg-icon>
                  </button>
                  <input [(ngModel)]="newEntry.GueltigVon" [matDatepicker]="dp1" required style="display: none">
                  <mat-datepicker #dp1
                                  (closed)="updateGueltigBisIfNeeded()"
                                  [touchUi]="true">
                  </mat-datepicker>
                </td>
              </tr>
              <tr>
                <td style="height: 47px">
                  <strong>Läuft ab</strong>
                </td>
                <td colspan="2">
                  <mat-checkbox [(ngModel)]="newHasGueltigBis" (change)="updateGueltigBisIfNeeded()"></mat-checkbox>
                </td>
              </tr>
              <tr *ngIf="newHasGueltigBis">
                <td style="height: 47px">
                  <strong>Gueltig bis</strong>
                </td>
                <td>
                  {{ newEntry.GueltigBis|date:'dd.MM.yyyy' }}
                </td>
                <td>
                  <button (click)="dp2.open()" mat-raised-button>
                    <svg-icon>kalender</svg-icon>
                  </button>
                  <input [(ngModel)]="newEntry.GueltigBis" [matDatepicker]="dp2" required style="display: none">
                  <mat-datepicker #dp2
                                  [touchUi]="true">
                  </mat-datepicker>
                </td>
              </tr>
              <tr>
                <td style="height: 47px">
                  <strong>Preis</strong>
                </td>
                <td colspan="2">
                  <mat-form-field appearance="outline">
                    <input matInput type="number" [(ngModel)]="newEntryPrice" required>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <td style="height: 47px">
                  <strong>Notiz/Info</strong>
                </td>
                <td colspan="2">
                  <mat-form-field appearance="outline">
                    <input matInput [(ngModel)]="newEntry.PreisText" maxlength="250">
                  </mat-form-field>
                </td>
              </tr>
            </table>

            <hr>
            <div style="padding: 5px; text-align: center">
              <button mat-raised-button (click)="saveNew()" style="margin-right: 5px" color="primary">
                <svg-icon>speichern</svg-icon> Speichern
              </button>
              <button mat-raised-button (click)="cancelNew()" color="warn">
                <svg-icon>abbrechen</svg-icon> Abbrechen
              </button>
            </div>
          </div>
        </div>
      </div>

    </ng-template>
  </div>
</div>
