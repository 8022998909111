<div class="registercontent" [class.mobile]="msvc.isMobile()|async" *ngIf="regConfig">
  <div>
    <div class="registerlogobox">
      <img class="logo" src="{{domaincfg.logofilename}}"/>
    </div>

    <div *ngIf="settings && state_done && state_ok" class="registerbox registerboxinfo">
      <div class="bar">Registrierung erfolgreich!</div>

      <p>Diese wird nun von unseren Mitarbeitern bearbeitet. Sie werden benachrichtigt sobald Ihr Benutzerkonto
        freigeschalten wurde!</p>

      <div style="text-align: center">
        <button color="primary" mat-raised-button routerLink="/login">
          Jetzt anmelden
        </button>
      </div>
    </div>

    <div *ngIf="settings && state_done && state_error" class="registerbox registerboxinfo">
      <div class="bar">Registrierung fehlgeschlagen!</div>

      <p>Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut!</p>

      <div style="text-align: center">
        <button (click)="resetState()" color="primary" mat-raised-button>
          Erneut versuchen
        </button>
      </div>
    </div>

    <div *ngIf="settings && !state_done" class="registerbox">
      <div class="bar">Registrieren</div>

      <hr>
      <div>
        Sind Sie bereits Kunde?
        <div>
          <mat-radio-group (change)="validate()" [(ngModel)]="req.is_customer">
            <mat-radio-button [value]="false" style="margin-right: 5px">Neukunde</mat-radio-button>
            <mat-radio-button [value]="true">Bereits Kunde</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="regConfig && regConfig.text != ''" style="text-align: center">
        <hr>
        <div [innerHTML]="regConfig.text|nl2br"></div>
        <mat-checkbox *ngIf="regConfig.required" [(ngModel)]="info_ok" required (change)="validate()">
          Zur Kenntniss nehmen
        </mat-checkbox>
        <hr>
      </div>

      <hr>
      <div>
        <div class="formbox05">
          <mat-form-field hintLabel="Max 40 Zeichen">
            <input #f_company_name (keyup)="validate()" [(ngModel)]="req.company_name" maxlength="40"
                   [errorStateMatcher]="error_company_name" matInput placeholder="Firmenname" required>
            <span *ngIf="!error_company_name.loading && !error_company_name.isError && req.company_name" matSuffix>
              <svg-icon matTooltip="OK" style="color: green">check</svg-icon>
            </span>
            <span *ngIf="!error_company_name.loading && error_company_name.isError" matSuffix>
              <svg-icon matTooltip="{{error_company_name.error_text}}" style="color: red">error</svg-icon>
            </span>
            <mat-error *ngIf="error_company_name.error_text">
              <svg-icon>error</svg-icon>
              {{error_company_name.error_text}}
            </mat-error>
            <mat-hint align="end">{{f_company_name.value?.length || 0}}/40</mat-hint>
          </mat-form-field>
        </div>
        <div class="formbox05">
          <mat-form-field>
            <input #f_company_uid (keyup)="validate()" [(ngModel)]="req.company_uid"
                   [errorStateMatcher]="error_company_uid" matInput required placeholder="UID Nummer" maxlength="14">
            <span *ngIf="!error_company_uid.loading && !error_company_uid.isError && req.company_uid" matSuffix>
              <svg-icon matTooltip="OK" style="color: green">check</svg-icon>
            </span>
            <span *ngIf="!error_company_uid.loading && error_company_uid.isError" matSuffix>
              <svg-icon matTooltip="{{error_company_uid.error_text}}" style="color: red">error</svg-icon>
            </span>
            <mat-error *ngIf="error_company_uid.error_text">
              <svg-icon>error</svg-icon>
              {{error_company_uid.error_text}}
            </mat-error>
            <mat-hint align="end">{{f_company_uid.value?.length || 0}}/14</mat-hint>
          </mat-form-field>
        </div>
        <hr>
        <div class="formbox05">
          <mat-form-field>
            <input #f_firstname (keyup)="validate()" [(ngModel)]="req.firstname" [errorStateMatcher]="error_firstname"
                   matInput placeholder="Vorname" required maxlength="40">
            <span *ngIf="!error_firstname.loading && !error_firstname.isError && req.firstname" matSuffix>
              <svg-icon matTooltip="OK" style="color: green">success</svg-icon>
            </span>
            <span *ngIf="!error_firstname.loading && error_firstname.isError" matSuffix>
              <svg-icon matTooltip="{{error_firstname.error_text}}" style="color: red">error</svg-icon>
            </span>
            <mat-error *ngIf="error_firstname.error_text">
              <svg-icon>error</svg-icon>
              {{error_firstname.error_text}}
            </mat-error>
            <mat-hint align="end">{{f_firstname.value?.length || 0}}/40</mat-hint>
          </mat-form-field>
        </div>
        <div class="formbox05">
          <mat-form-field>
            <input #f_lastname (keyup)="validate()" [(ngModel)]="req.lastname" [errorStateMatcher]="error_lastname"
                   matInput placeholder="Nachname" required maxlength="40">
            <span *ngIf="!error_lastname.loading && !error_lastname.isError && req.lastname" matSuffix>
              <svg-icon matTooltip="OK" style="color: green">success</svg-icon>
            </span>
            <span *ngIf="!error_lastname.loading && error_lastname.isError" matSuffix>
              <svg-icon matTooltip="{{error_lastname.error_text}}" style="color: red">error</svg-icon>
            </span>
            <mat-error *ngIf="error_lastname.error_text">
              <svg-icon>error</svg-icon>
              {{error_lastname.error_text}}
            </mat-error>
            <mat-hint align="end">{{f_lastname.value?.length || 0}}/40</mat-hint>
          </mat-form-field>
        </div>
        <hr>
        <div class="formbox05">
          <mat-form-field>
            <input #f_street (keyup)="validate()" [(ngModel)]="req.street" [errorStateMatcher]="error_street" matInput
                   placeholder="Strasse" required maxlength="60">
            <span *ngIf="!error_street.loading && !error_street.isError && req.street" matSuffix>
              <svg-icon matTooltip="OK" style="color: green">success</svg-icon>
            </span>
            <span *ngIf="!error_street.loading && error_street.isError" matSuffix>
              <svg-icon matTooltip="{{error_street.error_text}}" style="color: red">error</svg-icon>
            </span>
            <mat-error *ngIf="error_street.error_text">
              <svg-icon>error</svg-icon>
              {{error_street.error_text}}
            </mat-error>
            <mat-hint align="end">{{f_street.value?.length || 0}}/60</mat-hint>
          </mat-form-field>
        </div>
        <div class="formbox05">
          <mat-form-field>
            <input #f_housenr (keyup)="validate()" [(ngModel)]="req.housenr" [errorStateMatcher]="error_housenr"
                   matInput placeholder="Hausnummer" required maxlength="20">
            <span *ngIf="!error_housenr.loading && !error_housenr.isError && req.housenr" matSuffix>
              <svg-icon matTooltip="OK" style="color: green">success</svg-icon>
            </span>
            <span *ngIf="!error_housenr.loading && error_housenr.isError" matSuffix>
              <svg-icon matTooltip="{{error_housenr.error_text}}" style="color: red">error</svg-icon>
            </span>
            <mat-error *ngIf="error_housenr.error_text">
              <svg-icon>error</svg-icon>
              {{error_housenr.error_text}}
            </mat-error>
            <mat-hint align="end">{{f_housenr.value?.length || 0}}/20</mat-hint>
          </mat-form-field>
        </div>

        <hr>
        <div class="formbox033">
          <mat-form-field>
            <mat-select #f_country (selectionChange)="validate()" [(value)]="req.country"
                        [errorStateMatcher]="error_country"
                        placeholder="Land" required>
              <mat-option *ngFor="let c of settings.countries" [value]="c.code">{{c.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="formbox033">
          <mat-form-field>
            <input #f_plz (keyup)="validate()" [(ngModel)]="req.plz" [errorStateMatcher]="error_plz" matInput
                   placeholder="PLZ"
                   required maxlength="10">
            <span *ngIf="!error_plz.loading && !error_plz.isError && req.plz" matSuffix>
              <svg-icon matTooltip="OK" style="color: green">success</svg-icon>
            </span>
            <span *ngIf="!error_plz.loading && error_plz.isError" matSuffix>
              <svg-icon matTooltip="{{error_plz.error_text}}" style="color: red">error</svg-icon>
            </span>
            <mat-error *ngIf="error_plz.error_text">
              <svg-icon>error</svg-icon>
              {{error_plz.error_text}}
            </mat-error>
            <mat-hint align="end">{{f_plz.value?.length || 0}}/10</mat-hint>
          </mat-form-field>
        </div>
        <div class="formbox033">
          <mat-form-field>
            <input #f_city (keyup)="validate()" [(ngModel)]="req.city" [errorStateMatcher]="error_city" matInput
                   placeholder="Ort"
                   required maxlength="50">
            <span *ngIf="!error_city.loading && !error_city.isError && req.city" matSuffix>
              <svg-icon matTooltip="OK" style="color: green">success</svg-icon>
            </span>
            <span *ngIf="!error_city.loading && error_city.isError" matSuffix>
              <svg-icon matTooltip="{{error_city.error_text}}" style="color: red">error</svg-icon>
            </span>
            <mat-error *ngIf="error_city.error_text">
              <svg-icon>error</svg-icon>
              {{error_city.error_text}}
            </mat-error>
            <mat-hint align="end">{{f_city.value?.length || 0}}/50</mat-hint>
          </mat-form-field>
        </div>

        <hr>
        <div>
          <div class="formbox05">
            <mat-form-field>
              <input #f_telnr (keyup)="validate()" [(ngModel)]="req.telnr" [errorStateMatcher]="error_telnr"
                     matInput placeholder="Telefonnummer" required maxlength="30">
              <mat-hint>
                Bitte mit Ländervorwahl angeben (+43, +49, ...).
              </mat-hint>
              <span *ngIf="!error_telnr.loading && !error_telnr.isError && req.telnr" matSuffix>
                <svg-icon matTooltip="OK" style="color: green">success</svg-icon>
              </span>
              <span *ngIf="!error_telnr.loading && error_telnr.isError" matSuffix>
                <svg-icon matTooltip="{{error_telnr.error_text}}" style="color: red">error</svg-icon>
              </span>
              <mat-error *ngIf="error_telnr.error_text">
                <svg-icon>error</svg-icon>
                {{error_telnr.error_text}}
              </mat-error>
              <mat-hint align="end">{{f_telnr.value?.length || 0}}/30</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <hr>
        <div class="formbox05">
          <mat-form-field>
            <input #f_mail (keyup)="validate()" [(ngModel)]="req.mail" [errorStateMatcher]="error_mail" matInput
                   placeholder="Email Adresse" required maxlength="100">
            <mat-hint>
              Ihre Email Adresse dient zugleich als Benutzername!
            </mat-hint>
            <span *ngIf="error_mail.loading" matSuffix>
              <div style="white-space: nowrap">
                <mat-spinner [diameter]="15"></mat-spinner> Prüfe..
              </div>
            </span>
            <span *ngIf="!error_mail.loading && !error_mail.isError && req.mail != ''" matSuffix>
              <svg-icon matTooltip="OK" style="color: green">success</svg-icon>
            </span>
            <span *ngIf="!error_mail.loading && error_mail.isError" matSuffix>
              <svg-icon matTooltip="{{error_mail.error_text}}" style="color: red">error</svg-icon>
            </span>
            <mat-error *ngIf="error_mail.error_text">
              <svg-icon>error</svg-icon>
              {{error_mail.error_text}}
            </mat-error>
            <mat-hint align="end">{{f_mail.value?.length || 0}}/100</mat-hint>
          </mat-form-field>
        </div>
        <div class="formbox05">
          <mat-form-field>
            <input #f_password (keyup)="validate()" [(ngModel)]="req.password" [errorStateMatcher]="error_password"
                   matInput
                   placeholder="Passwort" required type="password">
            <mat-hint>
              Mindestanforderungen für Ihr Passwort: 8 Zeichen.
              Empfohlen: 8-10 Zeichen + Sonderzeichen + Groß-/Kleinschreibung.
            </mat-hint>
            <span *ngIf="!error_password.loading && !error_password.isError && req.password" matSuffix>
                <svg-icon matTooltip="OK" style="color: green">success</svg-icon>
              </span>
            <span *ngIf="!error_password.loading && error_password.isError" matSuffix>
                <svg-icon matTooltip="{{error_password.error_text}}" style="color: red">error</svg-icon>
              </span>
            <mat-error *ngIf="error_password.error_text">
              <svg-icon>error</svg-icon>
              {{error_password.error_text}}
            </mat-error>
          </mat-form-field>
        </div>
        <hr *ngIf="settings.interests.length > 0">
        <div *ngIf="settings.interests.length > 0">
          Interessiert an
          <div>
            <mat-checkbox *ngFor="let i of settings.interests" [(ngModel)]="interest_list[i]"
                          style="margin: 5px">{{i}}</mat-checkbox>
          </div>
        </div>
        <hr>
        <div>
          <mat-checkbox (change)="validate()" [(ngModel)]="req.agb">AGB's gelesen und akzeptiert</mat-checkbox>
          | <a routerLink="/docs/agbs">Lesen</a>
        </div>
        <div>
          <mat-checkbox (change)="validate()" [(ngModel)]="req.dsgvo">Datenschutzbestimmungen gelesen und akzeptiert
          </mat-checkbox>
          | <a routerLink="/docs/datenschutz">Lesen</a>
        </div>
        <hr>

        <div>
          <button (click)="send()" [disabled]="!valid" color="primary" mat-raised-button style="width: 100%">
            Registrierung absenden
          </button>
        </div>
        <div style="text-align: center; margin-top: 10px;">
          <button mat-raised-button routerLink="/login">
            <svg-icon>abbrechen</svg-icon>
            Abbrechen
          </button>
        </div>
      </div>


    </div>
  </div>
</div>
