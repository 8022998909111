<div class="mlmenu">
  <div *ngIf="!add_mode">
    <div class="title">Merkliste auswählen</div>
    <div (click)="addToMerkliste(ml)" *ngFor="let ml of mlsvc.merklisten$|async" class="menuitem">
      <svg-icon *ngIf="(mlsvc.primary_merkliste$|async) && (mlsvc.primary_merkliste$|async)?.id == ml.id"
                style="font-size: 12px; height: 12px">radiobutton-gefuellt
      </svg-icon>
      <svg-icon *ngIf="(mlsvc.primary_merkliste$|async) && (mlsvc.primary_merkliste$|async)?.id != ml.id"
                style="font-size: 12px; height: 12px">radiobutton-leer
      </svg-icon>
      {{ml.label}}
    </div>
    <div class="spacer"></div>
    <div (click)="add()" class="menuitem">
      <svg-icon>merkliste-hinzufuegen</svg-icon>
      Neue Merkliste
    </div>
  </div>
  <div *ngIf="add_mode">
    <div class="title">Neue Merkliste</div>
    <div class="paddedcontent">
      <mat-form-field>
        <input [(ngModel)]="newml.label" matInput
               placeholder="Bezeichnung">
      </mat-form-field>
      <br>
      <mat-checkbox [(ngModel)]="newml.shared">Geteilt</mat-checkbox>

      <hr>

      <button (click)="save()" mat-button>
        <svg-icon>speichern</svg-icon>
        Speichern & Hinzufügen
      </button>

      <button (click)="cancel()" mat-button>
        <svg-icon>abbrechen</svg-icon>
        Abbrechen
      </button>
    </div>
  </div>
</div>
