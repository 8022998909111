import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {SharedDialogsConfirmationComponent} from "../confirmation/shared.dialogs.confirmation.component";
import {FileBrowserEntry} from "../../../../models/file-browser-entry";
import {AppFileBrowserService} from "../../../../services/app.file-browser.service";
import {Component, OnInit} from "@angular/core";
import {FileBrowserUploadData} from "../../../../models/file-browser-upload-data";
import {HttpResponse} from "@angular/common/http";
import {AppMessageService} from "../../../../services/app.message.service";
import {AppPageloaderService} from "../../../../services/app.pageloader.service";
import {AppDialogsService} from "../../../../services/app.dialogs.service";

@Component({
  templateUrl: 'shared.dialogs.file-picker-dialog.component.html',
  styleUrls: ['shared.dialogs.file-picker-dialog.component.scss']
})
export class SharedDialogsFilePickerDialogComponent implements OnInit {

  files: FileBrowserEntry[];

  constructor(public dialogRef: MatDialogRef<SharedDialogsConfirmationComponent>, private svc: AppFileBrowserService,
              private msg: AppMessageService, private loader: AppPageloaderService) {
  }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.svc.getList().subscribe(f => {
      this.files = f;
    })
  }

  upload(data: FileBrowserUploadData) {
    this.svc.uploadFiles(data).subscribe(ev => {
      if (ev instanceof HttpResponse) {
        if (ev.body == true) {
          this.msg.info('Hochgeladen!');
        }
        this.load();
      } else {
        let type = ev.type;
        if (ev.total && ev.loaded) {
          let total = ev.total;
          let loaded = ev.loaded;
          let percentage = Math.round((100 * loaded) / total)
          this.loader.startPercentage(percentage);
        } else {
          this.loader.stopPercentage();
        }
      }
    });
  }

  new(entry: FileBrowserEntry) {
    const l = this.loader.start();
    this.svc.saveEntry(entry).subscribe(s => {
      this.load();
      this.msg.info('Ordner erstellt!');
      l.stop();
    });
  }

  edit(entry: FileBrowserEntry) {
    const l = this.loader.start();
    this.svc.saveEntry(entry).subscribe(s => {
      this.load();
      this.msg.info('Gespeichert!');
      l.stop();
    });
  }

  delete(entry: FileBrowserEntry) {
    const l = this.loader.start();
    this.svc.deleteEntry(entry).subscribe(s => {
      this.load();
      this.msg.info('Gelöscht!');
      l.stop();
    });
  }

  select(entry: FileBrowserEntry) {
    this.dialogRef.close(entry);
  }
}
