import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {Logger} from "./app.logger.service";
import {ActivatedRoute} from "@angular/router";

@Injectable({providedIn: 'root'})
export class AppLogService {
  constructor(private http: HttpClient, private cfg: AppConfigService, private route: ActivatedRoute) {
    Logger.isDebug = cfg.isDebug();
    Logger.enabled = localStorage.getItem('_debugLogging') == '1' ? true : false;
    route.queryParams.subscribe(qparams => {
      if (qparams && qparams['_debugLogging'] && qparams['_debugLogging'] == 1) {
        localStorage.setItem('_debugLogging', '1');
        alert("Debug Logging aktiviert!");
        location.href = '/';
      } else if (qparams && qparams['_debugLogging'] && qparams['_debugLogging'] == 0) {
        localStorage.removeItem('_debugLogging');
        alert("Debug Logging deaktiviert!");
        location.href = '/';
      }
    })
  }

  consoleLog(elem: any, message, ...params) {
    if (Logger.enabled) {
      let elemname = '';
      if (elem.constructor.name) {
        elemname = elem.constructor.name;
      }
      if (elem.id) {
        elemname = elem.id;
      }
      if (params.length > 0) {
        console.log(elemname + ': ' + message, ...params);
      } else {
        console.log(elemname + ': ' + message);
      }
    }
  }

  consoleDebug(elem: any, message, ...params) {
    if (Logger.enabled) {
      let elemname = '';
      if (elem.constructor.name) {
        elemname = elem.constructor.name;
      }
      if (elem.id) {
        elemname = elem.id;
      }
      if (params.length > 0) {
        // tslint:disable-next-line:no-console
        console.debug(elemname + ': ' + message, ...params);
      } else {
        // tslint:disable-next-line:no-console
        console.debug(elemname + ': ' + message);
      }
    }
  }
}
