import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import {AppMessageService} from '../services/app.message.service';
import {Observable, of} from 'rxjs';
import {AppLieferkundeService} from '../services/app.lieferkunde.service';
import {Lieferkunde} from '../models/lieferkunde';
import {AppEventService} from '../services/app.event.service';
import {AppLogService} from "../services/app.log.service";

@Injectable()
export class AppGuardsHasLieferkunde implements CanActivate, CanLoad, CanActivateChild {
  lieferkunde: Lieferkunde;

  constructor(private router: Router, private lksvc: AppLieferkundeService, private msg: AppMessageService,
              private esvc: AppEventService, private logger: AppLogService,) {
    this.lksvc.current_lieferkunde$.subscribe(lk => this.lieferkunde = lk);
  }

  testIsLieferkundeLoaded() {
    return (this.lksvc.current_lieferkunde.value instanceof Lieferkunde);
  }

  canActivate(route: ActivatedRouteSnapshot, rtsnap: RouterStateSnapshot): Observable<boolean> {
    let isLieferkundeLoaded = this.testIsLieferkundeLoaded();
    if (isLieferkundeLoaded) {
      return of(true);
    } else {
      this.logger.consoleDebug(this, 'canActivate - '+route.url+' -> Kein Lieferkunde geladen. Redirect to /lieferkunden');
      this.router.navigateByUrl('/lieferkunden');
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let isLieferkundeLoaded = this.testIsLieferkundeLoaded();
    this.logger.consoleDebug(this, 'canActivateChild - '+childRoute.url+' -> Kein Lieferkunde geladen');
    return of(isLieferkundeLoaded);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    let isLieferkundeLoaded = this.testIsLieferkundeLoaded();
    this.logger.consoleDebug(this, 'canLoad - '+route.path+' -> Kein Lieferkunde geladen');
    return of(isLieferkundeLoaded);
  }
}
