import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AppConditionsService} from '../services/app.conditions.service';
import {Condition} from '../modules/admin/model/condition';
import {AppConfigService} from '../services/app.config.service';
import {AppAsyncTimeout} from "../helpers/app.asyncTimeout";

@Injectable()
export class AppGuardsCondition implements CanActivate, CanActivateChild {
  conditions: Condition[];
  url_before_redirect = '';

  constructor(private router: Router, private svc: AppConditionsService, private cfg: AppConfigService) {
    svc.open_conditions.subscribe(cs => {
      this.conditions = cs;
    });
  }

  private test(route: ActivatedRouteSnapshot, rtsnap: RouterStateSnapshot, observer) {
    if (this.conditions) {
      if (this.conditions.length == 0) {
        observer.next(true);
        observer.complete();
      } else {
        let match = false;
        this.conditions.forEach(c => {
          if (!match && c.path_regexes) {
            c.path_regexes.forEach(regex => {
              regex = regex.replace(new RegExp('\/', 'g'), '\\/');
              const rregex = new RegExp(regex);

              if (!match && rtsnap.url.toString().match(rregex)) {
                match = true;
              }
            });
          } else if (!match) {
            if (!c.path_regexes || (c.path_regexes && c.path_regexes.length == 0)) {
              match = true;
            }
          }
        });

        if (match) {
          this.url_before_redirect = rtsnap.url.toString();
          this.router.navigateByUrl('/check-conditions');

        } else {
          observer.next(true);
          observer.complete();
        }
      }
    } else {
      AppAsyncTimeout.setTimeout(() => {
        this.test(route, rtsnap, observer);
      }, 10);
    }
  }

  canActivate(route: ActivatedRouteSnapshot, rtsnap: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer) => {
      this.test(route, rtsnap, observer);
    });

  }

  canActivateChild(route: ActivatedRouteSnapshot, rtsnap: RouterStateSnapshot): Observable<boolean> {
    return new Observable((observer) => {
      this.test(route, rtsnap, observer);
    });
  }
}
