import {Injectable} from '@angular/core';
import {AppConfigService} from './app.config.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NewsEntry} from '../models/news_entry';
import {map} from 'rxjs/operators';
import {NewsEntryTag} from '../models/news-entry-tag';
import {PagedResult} from '../models/pagedresult';
import {DownloadsEntry} from "../modules/downloads/models/downloads-entry";
import {NewsEntryFile} from "../models/news-entry-file";

@Injectable({providedIn: 'root'})
export class AppNewsService {
  constructor(private cfg: AppConfigService, private http: HttpClient) {
  }

  get(limit: number, tags: string[]): Observable<PagedResult<NewsEntry>> {
    return this.http.post<PagedResult<NewsEntry>>(
      this.cfg.buildUrl('/news/get'), {
        limit: limit,
        tags: tags
      }
    ).pipe(map(es => {
      es.data.forEach(e => {
        Object.setPrototypeOf(e, NewsEntry.prototype);
      });
      return es;
    }));
  }

  getById(id: number): Observable<NewsEntry> {
    return this.http.get<NewsEntry>(
      this.cfg.buildUrl('/news/get/' + id)
    ).pipe(map(e => {
      if (e) {
        Object.setPrototypeOf(e, NewsEntry.prototype);
      }
      return e;
    }));
  }

  getNewsEntryFile(entry: NewsEntry, file: NewsEntryFile) {
    const options = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders().set('Content-Type', file.mime_type)
    };

    return this.http.get<any>(
      '/news/attachment/' + entry.id + '/' + file.id + '/' + file.label,
      options
    ).pipe(map(res => {
      // trick to download store a file having its URL
      const fileURL = URL.createObjectURL(res);
      const a = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      a.download = file.label;
      document.body.appendChild(a);
      a.click();

      return true;
    }));
  }

  setSeen(entry: NewsEntry): Observable<boolean> {
    return this.http.get<boolean>(
      this.cfg.buildUrl('/news/' + entry.id.toString() + '/seen')
    );
  }

  toggleLike(entry: NewsEntry): Observable<{ ilike: boolean, likes: number }> {
    return this.http.get<{ ilike: boolean, likes: number }>(
      this.cfg.buildUrl('/news/' + entry.id.toString() + '/toggleLike')
    );
  }

  getTags(): Observable<NewsEntryTag[]> {
    return this.http.get<NewsEntryTag[]>(
      this.cfg.buildUrl('/news/tags')
    ).pipe(map(es => {
      es.forEach(e => {
        Object.setPrototypeOf(e, NewsEntryTag.prototype);
      });
      return es;
    }));
  }
}
