import {Injectable} from "@angular/core";
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {MatSnackBarRef} from "@angular/material/snack-bar";
import {AppEventService} from "./app.event.service";

@Injectable({providedIn: "root"})
export class AppSnackbarService {
  queue: {
    duration: number;
    component: any;
    data: any;
    onOpen: any;
    onDismissed: any;
  }[] = [];

  currentActive = false;

  currentActiveRef: MatSnackBarRef<any>;

  constructor(private snackBar: MatSnackBar, private esvc: AppEventService) {
    esvc.getQueue().subscribe(e => {
      if (e && e.name == 'gui.app_body_click') {
        this.closeCurrent();
      }
    })
  }

  private closeCurrent(){
    if (this.currentActiveRef) {
      this.currentActiveRef.dismiss();
    }
  }

  openMessage(msg, duration = null, onOpen = null, onDismissed = null) {
    this.open('msg', msg, duration , onOpen, onDismissed);
  }

  open(component, data = null, duration = null, onOpen = null, onDismissed = null) {
    this.queue.push({
      duration: duration,
      component: component,
      data: data,
      onOpen: onOpen,
      onDismissed: onDismissed
    });
    if (!this.currentActive) {
      this.openNext();
    }
  }

  private openNext() {
    if (this.queue.length > 0) {
      let wkkref;
      let next = this.queue.shift();

      this.currentActive = true;

      if (next.component == 'msg') {
        let config = null;
        if (next.duration) {
          config = {
            duration: next.duration
          };
        }

        wkkref = this.snackBar.open(next.data, null, config);

      } else {
        wkkref = this.snackBar.openFromComponent(next.component, {
          data: next.data,
          duration: next.duration,
        });
      }
      this.currentActiveRef = wkkref;
      if (next.onOpen) {
        next.onOpen(wkkref);
      }
      wkkref.afterDismissed().subscribe(() => {
        if (next.onDismissed) {
          next.onDismissed(wkkref);
        }
        this.currentActiveRef = undefined;

        this.openNext();
        this.currentActive = false;

      });
    }


  }
}
