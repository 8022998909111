import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {Artikel} from '../models/artikel';
import {ArtikelNote} from '../models/artikel-note';
import {BehaviorSubject} from 'rxjs';
import {AppSettingsService} from './app.app_settings.service';

@Injectable({providedIn: 'root'})
export class AppArticleNoteService {
  show_inline = new BehaviorSubject<boolean>(null);

  constructor(private http: HttpClient, private cfg: AppConfigService, private sett: AppSettingsService) {
    this.sett.getUserSettingValue('ShopViewArticleNoteInline').subscribe(s => this.show_inline.next(s));
  }

  getForArticle(art: Artikel) {
    return this.http.get<ArtikelNote>(
      this.cfg.buildUrl('/artikelnote/getByArtnr/' + art.Artikelstamm + '/' + art.ArtikelNummer)
    );
  }

  setForArticle(art: Artikel, note: ArtikelNote) {
    return this.http.post<ArtikelNote>(
      this.cfg.buildUrl('/artikelnote/setByArtnr/' + art.Artikelstamm + '/' + art.ArtikelNummer),
      {
        artikelnote: note.note
      }
    );
  }

  deleteForArticle(art: Artikel, note: ArtikelNote) {
    return this.http.get<ArtikelNote>(
      this.cfg.buildUrl('/artikelnote/deleteByArtnr/' + art.Artikelstamm + '/' + art.ArtikelNummer),
    );
  }
}
