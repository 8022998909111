import {Injectable} from '@angular/core';
import {DomainConfig} from '../models/domain-config';
import {HttpClient} from '@angular/common/http';
import {MatomoInjector} from '../modules/matamo/service/matomo-injector.service';
import {BehaviorSubject, Observable} from "rxjs";
import {DomainVertreterConfig} from "../models/domain-vertreter-config";
import {map} from "rxjs/operators";
import {DomainSuggestion} from "../models/domain-suggestion";

declare let require: any;
declare let window: any;
const {version: appVersion} = require('../../../package.json');

@Injectable({providedIn: 'root'})
export class AppConfigService {
  active_component = '';
  active_route = '';
  config: any;
  booted = false;
  domainconfig: DomainConfig;

  vertreterconfig = new BehaviorSubject<DomainVertreterConfig>(null);
  $vertreterconfig: Observable<DomainVertreterConfig> = this.vertreterconfig.asObservable();


  VAPID_PUBLIC_KEY = '';
  debug: false;
  matomo_enabled = false;
  private baseUrl = '';
  private eventbaseUrl = '';
  private default_message_duration = 2000;
  private matomo_init = false;

  constructor(private http: HttpClient, private matomo: MatomoInjector) {
  }

  loadVertreterConfig() {
    this.http.get<DomainVertreterConfig>(
      '/domain/vertreter'
    ).pipe(map(c => {
      if (c) {
        Object.setPrototypeOf(c, DomainVertreterConfig.prototype);
      }
      return c;

    })).subscribe(c => {
      this.vertreterconfig.next(c);
    });
  }

  featuresEnabled(feature_names: string[]) {
    let state = false;
    feature_names.forEach((feature_name) => {
      if (this.domainconfig.features.includes(feature_name)) {
        state = true;
      }
    });
    return state;
  }

  featuresEnabledAll(feature_names: string[]) {
    let state = true;
    feature_names.forEach((feature_name) => {
      if (!this.domainconfig.features.includes(feature_name)) {
        state = false;
      }
    });
    return state;
  }

  check_init_matomo() {
    if (this.domainconfig && this.domainconfig.matomo_url != '' && this.domainconfig.matomo_site_id &&
      this.domainconfig.matomo_site_id > 0 && !this.matomo_init) {
      this.matomo_init = true;
      this.matomo.init(this.domainconfig.matomo_url, this.domainconfig.matomo_site_id);
      this.matomo_enabled = true;
    }
  }

  getDomainSuggestions(): Observable<DomainSuggestion[]> {
    return this.http.get<DomainSuggestion[]>('/domain/getSuggestions')
  }

  loadDomainConfig(exec_after: any) {
    this.http.get(this.buildUrl('/domain/info')).subscribe({
      next: (cfg: DomainConfig) => {
        this.domainconfig = cfg;
        this.check_init_matomo();
        if (exec_after) {
          exec_after();
        }
      },
      error: (error: Error) => {
        console.error('Boot failed!');
      }
    });
  }

  getRegisterConfig(): Observable<{ text: string, required: boolean }> {
    return this.http.get<{ text: string, required: boolean }>(
      this.buildUrl('/domain/registerinfo')
    );
  }

  bootSVC(cfg: any) {
    if (cfg.api_url) {
      this.baseUrl = this.ensureUrl(cfg.api_url);
    }
    if (cfg.event_url) {
      this.eventbaseUrl = this.ensureWSUrl(cfg.event_url);
    }
    if (cfg.VAPID_PUBLIC_KEY) {
      this.VAPID_PUBLIC_KEY = cfg.VAPID_PUBLIC_KEY;
    }
    if (cfg.debug) {
      this.debug = cfg.debug;
    }

    window.SENTRY_DEBUG = this.debug;

    this.booted = true;
  }

  getVersion() {
    return appVersion;
  }

  buildUrl(path) {
    return this.baseUrl + path;
  }

  getDefaultMessageDuration() {
    return this.default_message_duration;
  }

  getEventSocketUrl() {
    return this.eventbaseUrl;
  }

  isDebug() {
    return this.debug;
  }

  private ensureUrl(url) {
    if (url.match(/^\/.*$/)) {
      url = window.location.protocol + '//' + window.location.hostname + url;
    }
    return url;
  }

  private ensureWSUrl(url) {
    if (url.match(/^\/.*$/)) {
      url = window.location.protocol + '//' + window.location.hostname + url;
    }
    url = url.replace('https', 'wss');
    url = url.replace('http', 'ws');
    return url;
  }
}
