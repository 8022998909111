import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AppUserService} from '../../services/app.user.service';
import {AppMessageService} from '../../services/app.message.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AppDialogsService} from '../../services/app.dialogs.service';
import {AppConfigService} from '../../services/app.config.service';
import {DomainConfig} from '../../models/domain-config';
import {AppPageTitleService} from '../../services/app.page-title.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {Subscription} from 'rxjs';
import {AppLieferkundeService} from "../../services/app.lieferkunde.service";
import {AppAsyncTimeout} from "../../helpers/app.asyncTimeout";

@Component({
  // moduleId: module.id,
  selector: 'app-login',
  templateUrl: 'app.login.component.html',
  styleUrls: ['app.login.component.scss']
})
export class AppLoginComponent implements OnInit, OnDestroy {
  static id = 'AppUserLoginComponent';
  model: any = {};
  loading = false;
  returnUrl: string;
  loggedin = false;

  domaincfg: DomainConfig;

  init = false;

  login_error: string;

  authsub: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private usvc: AppUserService,
              private lksvc: AppLieferkundeService,
              private msg: AppMessageService, private dialog: AppDialogsService, private cfg: AppConfigService,
              private tsvc: AppPageTitleService, private loader: AppPageloaderService) {

    this.domaincfg = cfg.domainconfig;
  }

  resetLogin() {
    this.login_error = undefined;
  }

  login() {
    this.loading = true;
    this.usvc.login(this.model.username, this.model.password)
      .subscribe({
        next: (data) => {
          if (data) {
            this.loader.startFullscreen('Willkommen ' + data.benutzerandruckname + '!', 'Wir bereiten alles vor');

            let lkwsub = this.usvc.lkWarmupDone.subscribe(s => {
              if (s) {
                AppAsyncTimeout.setTimeout(() => {
                  const redirurl = this.route.snapshot.queryParams['returnUrl'] || '/';
                  this.router.navigate([redirurl]);

                  this.loader.stopFullscreen();
                  lkwsub.unsubscribe();
                }, 50);
              }
            });


          } else {
            this.msg.error('Zugangsdaten falsch!');
          }
          this.loading = false;
        },
        error: (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.error && error.error.message) {
              this.login_error = error.error.message;
            }
          }

          this.loading = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.tsvc.resetTitle();

    if (this.authsub instanceof Subscription) {
      this.authsub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.tsvc.setTitle('Login');

    if (this.usvc.isLoggedin) {
      const redirurl = this.route.snapshot.queryParams['returnUrl'] || '/';
      this.router.navigate([redirurl]);
    }
  }
}
