import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfigService} from './app.config.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Warenkorb} from '../models/warenkorb';
import {WarenkorbEntry} from '../models/warenkorb-entry';
import {Artikel} from '../models/artikel';
import {AppEventService} from './app.event.service';
import {GuiMessage} from '../models/gui-message';
import {AppLieferkundeService} from './app.lieferkunde.service';
import {Lieferkunde} from '../models/lieferkunde';
import {AppPageloaderService} from './app.pageloader.service';
import {AppBestellungService} from './app.bestellung.service';
import {map} from 'rxjs/operators';
import {AppSettingsService} from './app.app_settings.service';
import {MerklistEntry} from "../models/merklist-entry";
import {BestellResult} from "../models/bestell-result";
import setPrototypeOf = Reflect.setPrototypeOf;

@Injectable({providedIn: 'root'})
export class AppWarenkorbService implements OnDestroy {
  warenkoerbe = new BehaviorSubject<Warenkorb[]>([]);
  warenkoerbe$ = this.warenkoerbe.asObservable();

  primary_warenkorb = new BehaviorSubject<Warenkorb>(null);
  primary_warenkorb$ = this.primary_warenkorb.asObservable();

  artnr_wk_map = new BehaviorSubject<[][]>([]);
  artnr_wk_map$ = this.artnr_wk_map.asObservable();

  artnr_wk_map2 = new BehaviorSubject<any[]>([]);
  artnr_wk_map2$ = this.artnr_wk_map2.asObservable();

  lieferkunde: Lieferkunde;

  posUpdatePending = false;

  booted = new BehaviorSubject<boolean>(false);

  pwkid;

  pwksub: Subscription;
  esub: Subscription;

  constructor(private http: HttpClient, private cfg: AppConfigService, private esvc: AppEventService,
              private lksvc: AppLieferkundeService, private loader: AppPageloaderService, private bsvc: AppBestellungService,
              private settsvc: AppSettingsService) {
    this.lksvc.current_lieferkunde$.subscribe(lk => {
      if (!this.lieferkunde || this.lieferkunde == null || !lk || this.lieferkunde.LieferkundeNr != lk.LieferkundeNr) {
        this.booted.next(false);
        this.primary_warenkorb.next(null);
        this.warenkoerbe.next([]);
      }
      this.lieferkunde = lk;
      if (this.lieferkunde) {
        this.load();
        /*if (!this.booted.value) {
          this.init();
        }*/

      } else {
        this.primary_warenkorb.next(null);
      }
    });

    this.esub = esvc.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\WarenkorbEngine\\WarenkorbChangedEvent') {
        if (!this.posUpdatePending) {
          this.load();
        }

      }
    });
    this.pwksub = this.primary_warenkorb.subscribe(wk => {
      if (wk) {
        this.pwkid = wk.id;

        if (this.pwkid !== 0) {
          this.settsvc.setUserSettingValue('GUI_PrimaryWarenkorbId', this.pwkid).subscribe();
        }
      }
    });
  }

  init() {
    this.settsvc.getUserSettingValue('GUI_PrimaryWarenkorbId').subscribe(v => {
      this.pwkid = v;
      this.init_primary();

    });
  }

  init_primary() {
    if (this.warenkoerbe.value && this.pwkid !== undefined) {
      let found = false;
      this.warenkoerbe.value.forEach(wk => {
        if (wk.id == this.pwkid) {
          this.primary_warenkorb.next(wk);
          found = true;
        }
      });

      if (!found) {
        this.primary_warenkorb.next(this.warenkoerbe.value[0]);
      }

      if (!this.booted.value) {
        this.booted.next(true);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }
    if (this.pwksub instanceof Subscription) {
      this.pwksub.unsubscribe();
    }
  }


  silentLoadArtNrMapping() {
    this.getArtNrMapping().subscribe(m => {
      this.artnr_wk_map.next(m);
      this.artnr_wk_map2.next(Object.keys(m));
    });
  }

  load() {
    this.loader.start('AppWarenkorbService_1');
    this.getWarenkoerbe().subscribe(wks => {
      this.warenkoerbe.next(wks);
      if (this.primary_warenkorb.getValue() === null || this.primary_warenkorb.getValue() === undefined) {
        this.init();
      } else {
        let found = false;
        const id = this.primary_warenkorb.getValue().id;
        wks.forEach(wk => {
          if (wk.id == id) {
            this.primary_warenkorb.next(wk);
            found = true;
          }
        });

        if (!found) {
          this.primary_warenkorb.next(wks[0]);


          if (!this.booted.value) {
            this.booted.next(true);
          }
        }
      }

      this.loader.stop('AppWarenkorbService_1');
    });

    this.silentLoadArtNrMapping();
  }

  sendWarenkorb(warenkorb: Warenkorb, confirmationMailCopyTo: string[] = []): Observable<BestellResult> {
    return this.http.post<BestellResult>(
      this.cfg.buildUrl('/warenkorb/' + warenkorb.id + '/send'),
      {
        confirmationMailCopyTo: confirmationMailCopyTo,
        reTage: warenkorb.reTage,
      },
    ).pipe(map(result => {
      this.bsvc.protoBestellung(result.bestellung);
      return result;
    }));
  }

  createWarenkorb(warenkorb: Warenkorb): Observable<Warenkorb> {
    return this.http.post<Warenkorb>(
      this.cfg.buildUrl('/warenkorb/add'),
      {
        warenkorb: warenkorb
      }
    ).pipe(map(wk => {
      this.protoWarenkorb(wk);
      return wk;
    }));
  }

  adminGetLocked(): Observable<Warenkorb[]> {
    return this.http.get<Warenkorb[]>(
      this.cfg.buildUrl('/warenkorb/locked'),
    ).pipe(map(wks => {
      wks.forEach(wk => {
        this.protoWarenkorb(wk);
      });

      return wks;
    }));
  }

  adminUnlock(wk: Warenkorb): Observable<boolean> {
    return this.http.get<boolean>(
      this.cfg.buildUrl('/warenkorb/' + wk.id + '/unlock'),
    );
  }

  updateWarenkorb(warenkorb: Warenkorb): Observable<Warenkorb> {
    return this.http.post<Warenkorb>(
      this.cfg.buildUrl('/warenkorb/' + warenkorb.id + '/update'),
      {
        warenkorb: warenkorb
      }
    ).pipe(map(wk => {
      this.protoWarenkorb(wk);
      return wk;
    }));
  }

  deleteWarenkorb(warenkorb: Warenkorb): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/warenkorb/' + warenkorb.id + '/delete'),
      {}
    );
  }

  emptyWarenkorb(warenkorb: Warenkorb): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/warenkorb/' + warenkorb.id + '/empty'),
      {}
    );
  }

  getWarenkoerbe(show_all = false, only_with_positions = false): Observable<Warenkorb[]> {
    return this.http.get<Warenkorb[]>(
      this.cfg.buildUrl('/warenkorb?all_lieferkunden=' + (show_all ? 't' : 'f')+'&only_with_positions='+(only_with_positions ? 't' : 'f'))
    ).pipe(map(wks => {
      wks.forEach(wk => {
        this.protoWarenkorb(wk);
      });
      return wks;
    }));
  }

  getArtNrMapping(): Observable<[][]> {
    return this.http.get<[][]>(
      this.cfg.buildUrl('/warenkorb/artNrMapping')
    );
  }

  getWarenkorbById(id): Observable<Warenkorb> {
    return this.http.get<Warenkorb>(
      this.cfg.buildUrl('/warenkorb/' + id)
    ).pipe(map(wk => {
      this.protoWarenkorb(wk);
      return wk;
    }));
  }

  getWarenkorbForCheckoutById(id): Observable<Warenkorb> {
    return this.http.get<Warenkorb>(
      this.cfg.buildUrl('/warenkorb/' + id + '/checkout')
    ).pipe(map(wk => {
      this.protoWarenkorb(wk);
      return wk;
    }));
  }

  addToWarenkorbById(id, view_slug, entry: WarenkorbEntry | MerklistEntry): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/warenkorb/' + id + '/add'),
      {
        view_slug: view_slug,
        entry: entry
      }
    );
  }

  addMultiToWarenkorbById(id, entries: WarenkorbEntry[] | MerklistEntry[]): Observable<GuiMessage[]> {
    return this.http.post<GuiMessage[]>(
      this.cfg.buildUrl('/warenkorb/' + id + '/addMulti'),
      {
        entries: entries
      }
    );
  }

  deleteFromWarenkorbById(id, posid): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/warenkorb/' + id + '/' + posid + '/delete'),
      {}
    );
  }

  duplicateFromWarenkorbById(id, posid): Observable<GuiMessage> {
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/warenkorb/' + id + '/' + posid + '/duplicate'),
      {}
    );
  }

  updateForWarenkorbById(id, entry: WarenkorbEntry): Observable<GuiMessage> {
    this.posUpdatePending = true;
    return this.http.post<GuiMessage>(
      this.cfg.buildUrl('/warenkorb/' + id + '/' + entry.id + '/update'),
      {
        entry: entry
      }
    ).pipe(map(m => {
      this.posUpdatePending = false;
      return m;
    }));
  }

  private protoWarenkorb(wk: Warenkorb) {
    if (wk) {
      setPrototypeOf(wk, Warenkorb.prototype);

      if (wk.entries) {
        wk.entries.forEach(e => {
          setPrototypeOf(e, WarenkorbEntry.prototype);

          if (e.artikel) {
            setPrototypeOf(e.artikel, Artikel.prototype);
          }
        });
      }
    }
  }
}
