import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {IAlbum, Lightbox} from "ngx-lightbox";
import {AppConfigService} from "../../../../services/app.config.service";
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'lightbox-image',
  templateUrl: 'shared.lightbox-image.component.html',
  styleUrls: ['shared.lightbox-image.component.scss']
})
export class SharedLightboxImageComponent implements OnInit, OnChanges {
  @Input() image: IAlbum;
  @Input() thumb_max_height = 250;


  imageData = new BehaviorSubject<any>(null);
  baseUrl: string;
  myimage = new BehaviorSubject<IAlbum>(null);

  constructor(public _lightbox: Lightbox, private cfg: AppConfigService, private http: HttpClient) {
    this.baseUrl = cfg.buildUrl('/');
  }

  ngOnInit() {
    this.init();
  }

  init(): void {
    if (this.image) {
      let $this = this;
      $this.imageData.next($this.baseUrl + $this.image.thumb);
      $this.myimage.next({
        thumb: $this.baseUrl + $this.image.thumb,
        src: $this.baseUrl + $this.image.src,
        downloadUrl: $this.baseUrl + $this.image.downloadUrl,
        caption: $this.image.caption
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.image) {
      this.init();
    }
  }

  open(): void {
    // open lightbox
    this._lightbox.open([this.myimage.value], 0);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }
}
