<div class="suggestionsbox" [class.mobile]="msvc.isMobile()|async">
  <h1 mat-dialog-title>
    Wir haben einen speziellen Shop für Ihre Kundenart gefunden!
  </h1>
  <div mat-dialog-content style="padding: 10px; text-align: center">
    Wir empfehlen Ihnen den Shop zu wechseln!<br><br>
    <div>
      <ng-container *ngFor="let sugg of suggestions">
        <a href="https://{{d.url}}" target="_self" *ngFor="let d of sugg.domains">
          <button mat-raised-button color="primary" matTooltip="Diesen Shop wählen">
            <strong>{{d.label}}</strong>
            <hr style="border-color: white; margin-bottom: 0;">
            <small>{{d.url}}</small>
        </button>
        </a>
      </ng-container>
    </div>
  </div>
  <div align="center" mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>
      Schließen
    </button>
  </div>
</div>
