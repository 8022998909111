<div class="globalSearchBoxBackground" [class.active]="focus"></div>
<div class="globalSearchBox tut_global-search-box" [class.focus]="focus" (focusin)="focusIn()" (focusout)="focusOut()">
  <div class="inner">
    <mat-form-field appearance="outline" [style.width.px]="widthPx">
      <input matInput #globalSearchInput [(ngModel)]="searchValue" (keydown.enter)="search()" placeholder="Suchen"
             (keyup)="keyUP()">
    </mat-form-field>
    <button (click)="reset($event)" color="primary" class="reset-button" mat-icon-button matTooltip="Suchen"
            *ngIf="has_search && focus">
      <svg-icon>error-clean</svg-icon>
    </button>
    <button mat-icon-button color="primary" (click)="search()" [disabled]="!searchReady">
      <svg-icon>suche</svg-icon>
    </button>
  </div>

  <div *ngIf="showResults|async" class="resultBox mat-elevation-z1">
    <div *ngIf="!(results|async)" class="infobox">
      <h3>Suche...</h3>
    </div>
    <div *ngIf="(results|async) && (results|async)?.length == 0" class="infobox">
      <h3>Keine Treffer</h3>
    </div>
    <div *ngIf="(results|async) && (results|async)?.length >= 0">
      <table class="table-body-striped">
        <tbody *ngFor="let resultGroup of (results|async); let last = last; let first = first;">
        <tr [class.headerRow]="!first">
          <td class="iconTd">
            <svg-icon *ngIf="resultGroup.icon">{{resultGroup.icon}}</svg-icon>
          </td>
          <td colspan="3" class="resultGroup-label">
            <div class="resultGroup-label">
              {{resultGroup.label}}
            </div>
            <div class="resultGroup-description" *ngIf="resultGroup.description">
              {{resultGroup.description}}
            </div>
          </td>
        </tr>
        <tr class="resultEntry" *ngFor="let resultEntry of resultGroup.entries" (click)="clickResultEntry(resultEntry)">
          <td class="entrySpacerTd"></td>
          <td class="entrySpacerTd"></td>
          <td class="iconTd">
            <svg-icon>{{resultEntry.icon}}</svg-icon>
          </td>
          <td>
            <div class="result-label">
              {{resultEntry.label}}
            </div>
            <div  class="result-description" *ngIf="resultEntry.description">
              {{resultEntry.description}}
            </div>
          </td>
        </tr>
        <tr *ngIf="!last">
          <td colspan="4">&nbsp;</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

