import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../../../../models/user';
import {AppUserService} from '../../../../services/app.user.service';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {AppNavbarService} from '../../../../services/app.navbar.service';
import {DashboardGridConfigItem} from '../../models/dashboard.grid-config-item';
import {CompactType, DisplayGrid, GridsterComponent, GridsterConfig, GridsterItem, GridType} from 'angular-gridster2';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DashboardDialogsAddWidgetComponent} from '../../dialogs/add-widget/dashboard.dialogs.add-widget.component';
import {DashboardWidgetService} from '../../services/dashboard.widget.service';
import {AppMessageService} from '../../../../services/app.message.service';
import {AppDialogsService} from '../../../../services/app.dialogs.service';
import {AppDashboardService} from "../../../../services/app.dashboard.service";
import {GridsterItemComponentInterface} from "angular-gridster2/lib/gridsterItem.interface";
import {AppMobileService} from "../../../../services/app.mobile.service";
import {takeUntil} from "rxjs/operators";

@Component({
  //changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-dashboard-view',
  templateUrl: 'dashboard.view.component.html',
  styleUrls: ['dashboard.view.component.scss']
})
export class DashboardViewComponent implements OnInit, OnDestroy {
  static id = 'DashboardViewComponent';
  user = new BehaviorSubject<User>(null);
  active_widgets = [];
  l1: any;
  columns = 20;
  rows = 20;
  on_drag = false;
  dashboard = new BehaviorSubject<DashboardGridConfigItem[]>(null);
  dashboardMobile = new BehaviorSubject<DashboardGridConfigItem[]>(null);
  available_widgets = [];
  edit_mode = new BehaviorSubject<boolean>(false);
  options = new BehaviorSubject<GridsterConfig>(null);
  default_config = [];

  isMobile = new BehaviorSubject<boolean>(false);

  _show = new BehaviorSubject<boolean>(false);

  _onDestroy = new Subject();

  detectChangesTimeout: any;

  @ViewChild(GridsterComponent) gridster: GridsterComponent;

  constructor(private usvc: AppUserService, private nbsvc: AppNavbarService, private dialog: MatDialog,
              private svc: DashboardWidgetService, private dbsvc: AppDashboardService, private cd: ChangeDetectorRef,
              private msg: AppMessageService, private dsvc: AppDialogsService, private msvc: AppMobileService) {
  }

  resize() {
    if (this.gridster) {
      this.gridster.onResize();
    }
  }

  show() {
    if (this._show.value != true) {
      this._show.next(true);
      this.init();
    }
  }

  detectChanges() {
    if (this.detectChangesTimeout) {
      clearTimeout(this.detectChangesTimeout);
    }
    this.detectChangesTimeout = setTimeout(() => {
      this.cd.detectChanges();
    }, 2000);
  }

  changedOptions(): void {
    this.setConfig();
    if (this.options.value.api && this.options.value.api.optionsChanged) {
      this.options.value.api.optionsChanged();
    }
  }

  removeItem(evt, item) {
    let d = this.dashboard.value;
    d.splice(d.indexOf(item), 1);
    this.dashboard.next(d);
    this.calcAvailableWidgets();
  }

  startEdit() {
    this.edit_mode.next(true);
    this.changedOptions();
  }

  endEdit() {
    this.edit_mode.next(false);
    this.changedOptions();
    this.saveDashboard();
  }

  endEdit2() {
    this.edit_mode.next(false);
    this.changedOptions();
    this.saveDomainDefaultDashboard();
  }

  cancelEdit() {
    this.edit_mode.next(false);
    this.changedOptions();
    this.loadDashboard();
  }

  calcAvailableWidgets(execAfter = null) {
    const available = [];
    this.svc.getAvailableWidgets().forEach(w => {
      let found = false;

      let curval = this.dashboard.value;
      if (curval === null) {
        curval = [];
      }

      curval.forEach(cfg => {
        if (cfg.widget == w.id) {
          found = true;
        }
      });
      if (!found) {
        available.push(w);
      }
    });

    this.available_widgets = available;

    if (execAfter) {
      execAfter();
    }
  }

  addWidget() {
    const ref = this.dialog.open(DashboardDialogsAddWidgetComponent, {
      data: this.available_widgets
    });
    ref.afterClosed().subscribe(ret => {
      if (ret) {
        const cfg = new DashboardGridConfigItem();
        cfg.widget = ret.id;
        cfg.cols = ret.cols;
        cfg.rows = ret.rows;
        let d = this.dashboard.value;
        d.push(cfg);
        this.dashboard.next(d);
        this.calcAvailableWidgets();
      }
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next(true);
    this._onDestroy.complete();
    this.nbsvc.clear();
  }

  setConfig() {
    this.options.next({
      gridType: GridType.Fit,
      compactType: CompactType.None,
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      minCols: this.columns,
      maxCols: this.columns,
      minRows: this.rows,
      maxRows: this.rows,
      maxItemCols: this.columns,
      minItemCols: 1,
      maxItemRows: this.rows,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 105,
      fixedRowHeight: 105,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      enableOccupiedCellDrop: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: this.edit_mode.value,
      },
      resizable: {
        enabled: this.edit_mode.value,
      },
      swap: false,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: {north: true, east: true, south: true, west: true},
      pushResizeItems: false,
      displayGrid: DisplayGrid.OnDragAndResize,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
      itemChangeCallback: (e, e2) => {
        if (e2.notPlaced) {
          this.notPlaced(e, e2);
        }
      },
      itemResizeCallback: (e, e2) => {
        if (e2.notPlaced) {
          this.notPlaced(e, e2);
        }
      },
    });
  }

  notPlaced(item: DashboardGridConfigItem | GridsterItem, elem: GridsterItemComponentInterface) {
    this.msg.error("Das gewünschte Widget "+item.widget+" hat leider nicht mehr Platz!");
    let d =this.dashboard.value;
    d.splice(d.length - 1, 1);
    this.dashboard.next(d);
    this.calcAvailableWidgets();
  }

  init() {
    this.loadDashboard();
  }

  ngOnInit(): void {
    this.setConfig();
    this.nbsvc.setLabel('Dashboard');
    this.usvc.user$.pipe(takeUntil(this._onDestroy)).subscribe(u => {
      this.user.next(u);
    });
    this.msvc.isMobile().pipe(takeUntil(this._onDestroy)).subscribe(m => {
      this.isMobile.next(m);
    })
  }

  saveDashboard() {
    this.dbsvc.setConfig(this.dashboard).subscribe(s => {
      if (s) {
        this.msg.info('Gespeichert!');
      }
    });
  }

  saveDomainDefaultDashboard() {
    this.dbsvc.setDomainDefaultConfig(this.dashboard).subscribe(s => {
      if (s) {
        this.msg.info('Für Domain Gespeichert!');
      }
    });
  }

  resetDashboard() {
    this.dsvc.confirm(
      '',
      'Dashboard zurücksetzen?',
      'Dadurch gehen Ihre Einstellungen verloren!',
      () => {
        this.dbsvc.setConfig('__DELETE__').subscribe(s => {
          this.loadDashboard();
          if (s) {
            this.msg.info('Zurückgesetzt');
          }
        });
      }
    );
  }

  resetDashboard2() {
    this.dsvc.confirm(
      '',
      'Domain Standard Dashboard zurücksetzen?',
      'Dadurch gehen Ihre Einstellungen verloren!',
      () => {
        this.dbsvc.setDomainDefaultConfig('__DELETE__').subscribe(s => {
          this.loadDashboard();
          if (s) {
            this.msg.info('Zurückgesetzt');
          }
        });
      }
    );
  }

  loadDashboard() {
    this.calcAvailableWidgets(() => {
      this.dbsvc.getConfig().subscribe(d => {
        let dashboard;
        let dashboardMobile;
        if (!d) {
          dashboard = this.default_config;
        } else {
          dashboard = d;
        }

        let dashboard_add = [];
        let dashboard_add_mobile = [];

        dashboard.forEach((e, idx) => {

          let found = false;
          this.available_widgets.forEach(w => {
            if (e.widget == w.id) {
              found = true;
            }
          })
          if (found) {
            dashboard_add.push(e);
          }
        });

        let order = [
          'DashboardWidgetsFavouriteMenuButtonsComponent',
          'DashboardWidgetsShopViewsComponent',
          'DashboardWidgetsImpWarenkorbTickerComponent',
          'DashboardWidgetsWarenkoerbeComponent',
          'DashboardWidgetsMerklistenComponent',
          'DashboardWidgetsCurrentWarenkorbComponent',
          'DashboardWidgetsNewsComponent'
        ];

        order.forEach(worder => {
          dashboard_add.some((e, idx) => {
            if (e.widget == worder) {
              dashboard_add_mobile.push({
                widget: worder
              });
              return true;
            } else {
              return false;
            }
          });
        })

        dashboard = dashboard_add;
        dashboardMobile = dashboard_add_mobile;

        this.dashboard.next(dashboard);
        this.dashboardMobile.next(dashboardMobile);
      });
    })
  }
}

