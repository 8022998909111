import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ContactFormData} from '../models/contact-form-data';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class SharedContactService {
  constructor(private http: HttpClient) {
  }

  sendForm(data: ContactFormData): Observable<boolean> {
    return this.http.post<boolean>(
      '/contact/sendform',
      {
        data: data
      }
    );
  }
}
