import {Component, Inject, OnInit} from '@angular/core';
import {AppConfigService} from '../../services/app.config.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {PrivacySettings} from '../../models/privacy-settings';

@Component({
  templateUrl: 'app.dialogs.privacy.component.html',
  styleUrls: ['app.dialogs.privacy.component.scss']
})
export class AppDialogsPrivacyComponent implements OnInit {
  details = false;

  keys = [];

  constructor(private ref: MatDialogRef<AppDialogsPrivacyComponent>,
              @Inject(MAT_DIALOG_DATA) public data: PrivacySettings, public cfg: AppConfigService) {
  }

  ngOnInit(): void {
    this.keys = Object.keys(this.data);
  }

  showDetails() {
    this.details = true;
  }

  hideDetails() {
    this.details = false;
  }

  close() {
    this.ref.close(this.data);
  }

  acceptAll() {
    Object.keys(this.data).forEach(k => {
      this.data[k] = true;
    });

    this.close();
  }
}
