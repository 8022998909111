<div *ngIf="entry">
  <h1 mat-dialog-title>
    <table>
      <tr>
        <td style="vertical-align: top">
          <svg-icon>in-warenkorb</svg-icon>
        </td>
        <td style="vertical-align: top">
          {{entry.artikel.ArtikelBezeichnung}} mit Kondition hinzufügen
        </td>
      </tr>
    </table>
  </h1>
  <div mat-dialog-content style="border-top: 1px solid lightgrey; border-bottom: 1px solid lightgrey; padding: 10px; min-width: 200px; min-height: 100px" class="wkadddiv">
    <table>
      <tr>
        <td>
          In folgenden Warenkorb legen:
        </td>
        <td>
          <mat-form-field>
            <mat-select [(value)]="wk" placeholder="">
              <mat-option *ngFor="let w of wksvc.warenkoerbe$|async" [value]="w">
                {{w.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>

      <tr *ngIf="(vertreterconfig|async)?.DomainVertreterShowPositionsart">
        <td>
          Positionsart:
        </td>
        <td>
          <mat-form-field class="positionsartinput">
            <mat-select [(value)]="entry.positionsart" (change)="change()">
              <mat-option [value]="'Standard'">Standard</mat-option>
              <mat-option [value]="'OhneVerrechnung'">Ohne Verrechnung</mat-option>
              <mat-option [value]="'NurVerrechnung'">Nur Verrechnung</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>

      <tr *ngIf="(vertreterconfig|async)?.DomainVertreterShowFreimenge">
        <td>
          Freimenge:
        </td>
        <td>
          <mat-form-field class="freimengeinput">
            <input [(ngModel)]="entry.freimenge" [disabled]="disabled" [size]="2" (change)="change()" matInput
                   matTooltip="Freimenge (0-x)">
          </mat-form-field>
        </td>
      </tr>

     <tr *ngIf="(vertreterconfig|async)?.DomainVertreterShowAktionsrabattmenge">
       <td>
         Aktionsrabatt Menge:
       </td>
       <td>
         <mat-form-field class="aktionsrabattmengeinput">
           <input [(ngModel)]="entry.aktionsrabattmenge" [disabled]="disabled" [size]="2" (change)="change()" matInput
                  type="number" matTooltip="Aktionsrabatt Menge (0-x)" [step]="1" [min]="0">
         </mat-form-field>
       </td>
     </tr>

      <tr *ngIf="(vertreterconfig|async)?.DomainVertreterShowAktionsrabattprozent">
        <td>
          Aktionsrabatt Prozent:
        </td>
        <td>
          <mat-form-field class="aktionsrabattprozentinput">
            <input [(ngModel)]="entry.aktionsrabattprozent" [disabled]="disabled" [size]="2" (change)="change()" matInput
                   type="number" matTooltip="Aktionsrabatt Prozent (0-100)" [step]="1" [min]="0" [max]="100">
          </mat-form-field>
        </td>
      </tr>

      <tr *ngIf="(vertreterconfig|async)?.DomainVertreterShowBasisverrechnungspreis">
        <td>
          Basisverrechnungspreis:
        </td>
        <td>
          <mat-form-field class="basisverrechnungspreisinput">
            <div style="height: 16px">
              <input [(ngModel)]="entry.basisverrechnungspreis" [disabled]="disabled" [size]="4" (change)="change()" matInput
                     type="number" step="0.01" matTooltip="Basisverrechnungspreis (0-x)">
            </div>
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <td>
          Menge:
        </td>
        <td>
          <mat-form-field class="mengeinput">
            <input [(ngModel)]="entry.menge" [disabled]="disabled" [size]="2" (change)="change()" matInput
                   matTooltip="Menge (0-x)" [min]="0" [step]="1">
          </mat-form-field>
        </td>
      </tr>
    </table>
  </div>
  <div style="display: block ;text-align: center" mat-dialog-actions>
    <button mat-raised-button (click)="add()">
      <svg-icon>in-warenkorb</svg-icon>
      Hinzufügen
    </button>

    <button mat-raised-button (click)="close()">
      <svg-icon>abbrechen</svg-icon>
      Abbrechen
    </button>
  </div>
</div>
