import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA} from '@angular/material/legacy-snack-bar';
import {Verfuegbarkeitsanfrage} from '../../models/verfuegbarkeitsanfrage';

@Component({
  templateUrl: 'shop.artikel-verfuegbarkeit-info.component.html',
  styleUrls: ['shop.artikel-verfuegbarkeit-info.component.scss']
})
export class ShopArtikelVerfuegbarkeitInfoComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Verfuegbarkeitsanfrage) {
    this.render();
  }

  render() {
    let lieferung = false;
    if (this.data.status.Verfuegbarkeitsinformation) {
      this.data.status.Verfuegbarkeitsinformation.forEach(vi => {
        if (vi.LieferungMoeglich) {
          if (vi.InfoText == 'Teilmenge lagernd') {
            vi.color = 'orange2';
          } else {
            vi.color = 'green';
          }

        } else if (vi.InfoCode == 'BS') {
          vi.color = 'orange';

        } else {
          vi.color = 'red';
        }
      });
    }
  }

  keydown(event) {
    event.stopPropagation();
  }
}
