import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA} from '@angular/material/legacy-snack-bar';
import {WarenkorbActionInfo} from '../../../../models/warenkorb-action-info';

@Component({
  templateUrl: 'shop.warenkorb-action-info.component.html',
  styleUrls: ['shop.warenkorb-action-info.component.scss']
})
export class ShopWarenkorbActionInfoComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: WarenkorbActionInfo) {

  }

  keydown(event) {
    event.stopPropagation();
  }
}
