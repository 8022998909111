import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {AppUserService} from '../../../../services/app.user.service';
import {User} from '../../../../models/user';
import {BehaviorSubject, Subscription} from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line:component-selector
  selector: 'view-field-label-render',
  templateUrl: 'shared.view-field-label-renderer.component.html',
  styleUrls: ['shared.view-field-label-renderer.component.scss']
})
export class SharedViewFieldLabelRendererComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() fieldname: string;
  @Input() mode = 'table';
  @Input() customTooltip: string;

  user: User;
  usub: Subscription;

  label = new BehaviorSubject<string>('');
  tooltip = new BehaviorSubject<string>('');

  constructor(private usvc: AppUserService, private cdr: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    if (this.usub instanceof Subscription) {
      this.usub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.usub = this.usvc.user$.subscribe(u => this.user = u);

    this.label.next(this.getNiceLabel());
    if (this.customTooltip) {
      this.tooltip.next(this.customTooltip);
    } else {
      this.tooltip.next(this.getNiceTooltip());
    }
  }

  ngAfterViewInit(): void {
  }

  getNiceLabel(): string {
    switch (this.fieldname) {
      case 'bezeichnung':
        return 'Bezeichnung';
      case 'darreichungsform':
        return 'DAR';
      case 'hersteller':
        return 'Hersteller';
      case 'pzn':
        return 'PZN';
      case 'verkaufspreis':
        return 'VP';
      case 'einkaufspreis':
        return 'EP';
      case 'rezeptzeichen':
        return 'RZ';
      case 'istarzneimittel':
        return 'AM';
      case 'status':
        return 'Verfüg.';
      case 'mwst':
        return 'MwSt.';
      case 'artikelnummer':
        return 'Artikelnummer';
      case 'Zulassungsnummer':
        return 'Zulassungsnummer';

      default:
        return this.fieldname;
    }
  }

  getNiceTooltip(): string {
    switch (this.fieldname) {
      case 'logo1':
        return 'Produktbild';
      case 'logo2':
        return 'Produktbild(2)';
      case 'bezeichnung':
        return 'Bezeichnung';
      case 'darreichungsform':
        return 'Darreichungsform';
      case 'hersteller':
        return 'Hersteller';
      case 'pzn':
        return 'Pharmazentralnummer';
      case 'verkaufspreis':
        return 'Verkaufspreis ';
      case 'einkaufspreis':
        return 'Einkaufspreis';
      case 'rezeptzeichen':
        return 'Rezeptzeichen';
      case 'istarzneimittel':
        return 'Ist Arzneimittel';
      case 'status':
        return 'Verfügbarkeitsstatus';
      case 'mwst':
        return 'Mehrwertsteuersatz';
      case 'artikelnummer':
        return 'Artikelnummer';


      default:
        return this.fieldname;
    }
  }
}
