import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Setting} from '../../../../models/setting';
import {SettingsBoxInterface} from '../../../../interfaces/settings-box-interface';
import {UntypedFormControl} from '@angular/forms';
import {SettingGroup} from '../../../../models/setting-group';
import {SettingSubGroup} from '../../../../models/setting-sub-group';
import {AppPageloaderService} from '../../../../services/app.pageloader.service';
import {Color} from "@angular-material-components/color-picker";

@Component({
  selector: 'settings-box',
  templateUrl: 'shared.settings-box.component.html',
  styleUrls: ['shared.settings-box.component.scss']
})
export class SharedSettingsBoxComponent implements OnInit, OnChanges {
  @Input() settings: Setting[];

  @Input() saveReady: boolean;
  @Output() saveReadyChange = new EventEmitter();

  @Input() value: any;
  @Output() valueChange = new EventEmitter();

  @Input() settings_if_parent: SettingsBoxInterface;

  @Output() settingsLoaded = new EventEmitter();

  constructor(private loader: AppPageloaderService) {
  }

  values = {};
  states = {};
  fcontrols = {};

  groups: SettingGroup[];

  clear(id) {
    this.value[id] = null;
    this.fcontrols[id].setValue(this.value[id]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.settings) {
      this.render();
    }
  }

  ngOnInit(): void {
    this.render();
  }

  private getRGBfromHEX(hexadecimal): { r: number, g: number, b: number, a: number } {
    const h = '0123456789ABCDEF';

    let hex = "";
    if (typeof hexadecimal == "string") {
      hex = hexadecimal.toUpperCase().replace('#', '');
    }

    const hexLength = hex.length;

    if (hexLength > 0 && hexLength <= 6) {
      let r = 0;
      let g = 0;
      let b = 0;
      if (hexLength == 6) {
        r = h.indexOf(hex[0]) * 16 + h.indexOf(hex[1]);
        g = h.indexOf(hex[2]) * 16 + h.indexOf(hex[3]);
        b = h.indexOf(hex[4]) * 16 + h.indexOf(hex[5]);
      } else if (hexLength == 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];

        r = h.indexOf(hex[0]) * 16 + h.indexOf(hex[0]);
        g = h.indexOf(hex[1]) * 16 + h.indexOf(hex[1]);
        b = h.indexOf(hex[2]) * 16 + h.indexOf(hex[2]);

      } else {
        const loopLength = 6 - hexLength;

        let zeros = '';
        let i;
        for (i = 0; i < loopLength; i++) {
          zeros += '0';
        }
        hex = zeros + hex;

        r = h.indexOf(hex[0]) * 16 + h.indexOf(hex[1]);
        g = h.indexOf(hex[2]) * 16 + h.indexOf(hex[3]);
        b = h.indexOf(hex[4]) * 16 + h.indexOf(hex[5]);
      }

      // OUTPUT
      return {
        r: r,
        g: g,
        b: b,
        a: 1
      };
    } else {
      return {
        r: 0,
        g: 0,
        b: 0,
        a: 1
      };
    }
  }

  private render() {
    const tmp: SettingGroup[] = [];

    if (this.settings) {
      this.settings.forEach(set => {
        if (set.special_type == 'color') {
          if (set.value) {
            const color = this.getRGBfromHEX(set.value);
            const colorObj = new Color(color.r, color.g, color.b, color.a);
            this.values[set.id] = colorObj;
          } else {
            this.values[set.id] = set.value;
          }

        } else {
          this.values[set.id] = set.value;
        }

        this.states[set.id] = true;

        const f = new UntypedFormControl(this.values[set.id], []);
        this.fcontrols[set.id] = f;

        let found = false;
        tmp.forEach(grp => {
          if (grp.name == set.group) {
            found = true;

            if (!grp.groups) {
              grp.groups = [];
            }

            let found2 = false;

            grp.groups.forEach(sgrp => {
              if (sgrp.name == set.subgroup) {
                sgrp.entries.push(set);
                found2 = true;
              }
            });

            if (!found2) {
              const tmp3 = new SettingSubGroup();
              tmp3.name = set.subgroup;
              tmp3.entries = [set];
              grp.groups.push(tmp3);
            }
          }
        });
        if (!found) {
          const tmp2 = new SettingGroup();
          tmp2.name = set.group;
          const tmp3 = new SettingSubGroup();
          tmp3.name = set.subgroup;
          tmp3.entries = [set];
          tmp2.groups = [tmp3];
          tmp.push(tmp2);
        }
      });

      tmp.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      tmp.forEach(grp => {
        grp.groups.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        grp.groups.forEach(g => {
          g.entries.sort(function (a, b) {
            if (a.weight < b.weight) {
              return -1;
            } else if (a.weight > b.weight) {
              return 1;
            } else {
              if (a.name < b.name) {
                return -1;
              } else if (a.name > b.name) {
                return 1;
              } else {
                return 0;
              }
            }
          });
        });
      });

      this.groups = tmp;
      this.settingsLoaded.next(true);
    }
  }

  onClick(id) {
    if (this.settings_if_parent) {
      this.settings_if_parent.onClick(id);
    }
  }

  changeData(id: any, value = null, elem: any = null) {
    const fc: UntypedFormControl = this.fcontrols[id];
    if (value) {
      this.values[id] = value;
    } else {
      this.values[id] = fc.value;
    }

    if (elem && elem.special_type == 'color') {
      if (this.values[id] instanceof Color) {
        this.values[id] = '#'+this.values[id].hex;
      }
    }


    if (this.settings_if_parent) {
      const l = this.loader.start();
      this.settings_if_parent.onTest(id, this.values[id]).subscribe({
        next: (s) => {
          if (!s) {
            fc.setErrors(['Error']);
          } else {
            fc.setErrors(null);
          }
          this.saveReady = s;
          this.saveReadyChange.emit(this.saveReady);
          l.stop();

        }, error: () => {
          this.saveReady = true;

          let state = true;
          Object.keys(this.fcontrols).forEach(myid => {
            const control: UntypedFormControl = this.fcontrols[myid];
            if (!control.valid) {
              state = false;
            }
          });

          this.saveReady = state;
          this.saveReadyChange.emit(this.saveReady);
          l.stop();
        }
      });
    }

    const tmp = this.settings;
    tmp.forEach(t => {
      if (this.values[t.id] !== undefined) {
        t.value = this.values[t.id];
      }
    });

    this.value = tmp;
    this.valueChange.emit(this.value);
  }
}
