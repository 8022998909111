import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {AppBonusPunkteService} from '../../../../services/app.bonus-punkte.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {
  ShopDialogsBonusPunkteDetailsComponent
} from '../../dialogs/bonus-punkte-details/shop.dialogs.bonus-punkte-details.component';
import {KundeBonuspunkt} from '../../../../models/kunde-bonuspunkt';
import {AppEventService} from '../../../../services/app.event.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'bonus-punkte-icons',
  templateUrl: 'shop.bonus-punkte-icons.component.html',
  styleUrls: ['shop.bonus-punkte-icons.component.scss']
})
export class ShopBonusPunkteIconsComponent implements OnInit, OnDestroy {

  punkte = new BehaviorSubject<KundeBonuspunkt[]>([]);

  esub: Subscription;

  constructor(private svc: AppBonusPunkteService, private dialog: MatDialog, private esvc: AppEventService) {
  }

  ngOnDestroy(): void {
    if (this.esub instanceof Subscription) {
      this.esub.unsubscribe();
    }
  }

  showDetails(p: KundeBonuspunkt) {
    const ref = this.dialog.open(ShopDialogsBonusPunkteDetailsComponent, {
      data: p
    });
  }

  getTooltip(p: KundeBonuspunkt) {
    const plabel = 'Punkte';
    const curlevel = p.level;
    const curpoints = p.Bonuspunkte;
    const ptonextlevel = p.ptonextlevel;
    const curpercentage = p.prozent;

    let label = p.Bonuspunkteprogramm.Bezeichnung + ' - Level ' + curlevel + ' (' + curpercentage + ' %) - ' + curpoints + ' ' + plabel;

    if (ptonextlevel > 0) {
      label += ' (noch ' + ptonextlevel + ' ' + plabel + ' bis zum nächsten Level)';
    }

    return label;
  }

  load() {
    this.svc.getMeine().subscribe(ps => {
      ps.forEach(p => {
        p.level = 0;
        p.prozent = '0';
        p.ptonextlevel = 0;
        if (p.Bonuspunkteprogramm.BonuspunktprogrammStufen[0]) {
          p.ptonextlevel = p.Bonuspunkteprogramm.BonuspunktprogrammStufen[0].AbBonuspunktSaldo - p.Bonuspunkte;
        }

        p.Bonuspunkteprogramm.BonuspunktprogrammStufen.forEach((s, idx) => {
          s.level = idx + 1;
          if (p.Bonuspunkte >= s.AbBonuspunktSaldo) {
            p.prozent = s.GutschriftProzent;
            p.level = s.level;
            if (p.Bonuspunkteprogramm.BonuspunktprogrammStufen[idx + 1]) {
              p.ptonextlevel = p.Bonuspunkteprogramm.BonuspunktprogrammStufen[idx + 1].AbBonuspunktSaldo - p.Bonuspunkte;
            }
          }
        });
      });

      this.punkte.next(ps);
    });
  }

  ngOnInit(): void {
    this.load();
    this.esub = this.esvc.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\BonuspunkteEngine\\BonuspunkteChangedEvent') {
        this.load();
      }
    });
  }
}
