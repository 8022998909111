<mat-form-field class="niceinput {{class}}" [class.showinputbuttons]="hasFocus|async" appearance="outline"
                (focusin)="_focusIn()" (focusout)="_focusOut()">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input [(ngModel)]="value" [disabled]="disabled" [size]="inputSize" (keyup)="_change()"
         (focusin)="_focIn(inputNgModel, $event)" (focusout)="_focOut(inputNgModel)" matInput type="number"
         #inputNgModel="ngModel"
         matTooltip="{{tooltip}}" (keydown.enter)="_mengeEnter()" [min]="min" [step]="step" [max]="max">
  <div class="inputbuttons mat-elevation-z1" (click)="_clickInputDiv()">
    <button mat-icon-button (click)="cntPlus(step, max, $event)" (focusout)="_focusOut()">
      <svg-icon>plus</svg-icon>
    </button>
    {{ focusLastVal }}
    <button mat-icon-button style="margin-left: 5px" (click)="cntMinus(step, min, $event)" (focusout)="_focusOut()">
      <svg-icon>minus</svg-icon>
    </button>
  </div>
</mat-form-field>
