import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CustomerSurvey} from '../../models/customer-survey';
import {CustomerSurveyAnswer} from '../../models/customer-survey-answer';
import {AppMobileService} from '../../services/app.mobile.service';
import {AppCustomerSurveyService} from '../../services/app.customer-survey.service';
import {AppPageloaderService} from '../../services/app.pageloader.service';
import {AppMessageService} from '../../services/app.message.service';

@Component({
  templateUrl: 'app.dialogs.customer-survey.component.html',
  styleUrls: ['app.dialogs.customer-survey.component.scss']
})
export class AppDialogsCustomerSurveyComponent implements OnInit {
  ratings = [];

  current_rating = 0;

  current_hover_rating = null;

  hovering = false;

  valid = false;

  answer = new CustomerSurveyAnswer();

  answers = [];

  active_answers = [];

  constructor(public dialogRef: MatDialogRef<AppDialogsCustomerSurveyComponent>,
              @Inject(MAT_DIALOG_DATA) public survey: CustomerSurvey, private svc: AppCustomerSurveyService,
              public msvc: AppMobileService, private loader: AppPageloaderService, private msg: AppMessageService) {
  }

  change_answer(idx) {
    if (!this.survey.multi_answers) {
      for (const i of Object.keys(this.answers)) {
        if (i != idx) {
          this.answers[i] = false;
        }
      }
    }

    this.active_answers = [];

    for (const i of Object.keys(this.answers)) {
      if (this.answers[i] == true) {
        this.active_answers.push(this.survey.answers[i]);
      }
    }

    this.validate();
  }

  hoverStartStar(r) {
    this.hovering = true;
    this.current_hover_rating = r;
  }

  hoverEndStar() {
    this.hovering = false;
    this.current_hover_rating = 0;
  }

  setRating(r) {
    this.current_rating = r;
    this.validate();
  }

  ngOnInit(): void {
    if (this.survey.type == 'rating') {
      this.ratings = this.getRatings();
    }
  }

  send() {
    if (this.survey.type == 'rating') {
      this.answer.answer = this.current_rating;

    } else if (this.survey.type == 'question') {
      this.answer.answer = this.active_answers;
    }

    const l = this.loader.start();
    this.svc.answer(this.survey, this.answer).subscribe(s => {
      this.msg.info('Danke vielmals!');
      l.stop();
    });

    this.dialogRef.close(true);
  }

  validate() {
    if (this.survey.type == 'rating' && this.current_rating > 0) {
      this.valid = true;

    } else if (this.survey.type == 'question' && this.active_answers.length > 0) {
      this.valid = true;

    } else {
      this.valid = false;
    }
  }

  getRatings() {
    const ratings = [];
    let i = 1;
    while (i <= this.survey.max_rating) {
      ratings.push(i);
      i++;
    }

    return ratings;
  }

}
