<button (click)="startedit($event)" *ngIf="!note && !(edit_mode|async)" mat-icon-button
        matTooltip="Keine Notiz (Klicken zum Bearbeiten)">
  <svg-icon>artikel-notiz</svg-icon>
</button>

<button (click)="startedit($event)" *ngIf="note && !(edit_mode|async)" mat-icon-button
        matTooltip="{{note.note}} (Klicken zum Bearbeiten)">
  <svg-icon color="green">artikel-notiz</svg-icon>
</button>

<div *ngIf="(svc.show_inline|async) && !(edit_mode|async) && note && note.note" [innerHTML]="note.note|nl2br|raw"
     style="padding-left: 5px;">
</div>

<div *ngIf="edit_mode|async" style="text-align: center">
  <mat-form-field (click)="$event.stopImmediatePropagation()">
    <textarea [(ngModel)]="note.note" cols="20"
              matInput
              placeholder="Notiz"
              rows="5"
    ></textarea>
  </mat-form-field>
  <br>

  <button (click)="save($event)" color="primary" mat-icon-button matTooltip="Speichern">
    <svg-icon>speichern</svg-icon>
  </button>
  <button (click)="delete($event)" color="warn" mat-icon-button matTooltip="Löschen">
    <svg-icon>loeschen</svg-icon>
  </button>
  <button (click)="cancel($event)" mat-icon-button matTooltip="Abbrechen">
    <svg-icon>abbrechen</svg-icon>
  </button>
</div>



