import {Component, OnInit} from "@angular/core";
import {AppUserService} from "../../services/app.user.service";
import {AppConfigService} from "../../services/app.config.service";
import {DomainSuggestion} from "../../models/domain-suggestion";
import {
  AppDialogsDomainSuggestionsComponent
} from "../../dialogs/domain-suggestions/app.dialogs.domain-suggestions.component";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {AppLieferkundeService} from "../../services/app.lieferkunde.service";

@Component({
  selector: 'domain-suggestions',
  templateUrl: 'app.domain-suggestions.component.html',
  styleUrls: ['app.domain-suggestions.component.scss']
})
export class AppDomainSuggestionsComponent implements OnInit {

  currentLKNr;

  constructor(private lksvc: AppLieferkundeService, private cfg: AppConfigService, private dialog: MatDialog) {
  }


  ngOnInit(): void {
    this.lksvc.current_lieferkunde$.subscribe(lk => {
      if (lk) {
        if (!this.currentLKNr || this.currentLKNr != lk.LieferkundeNr) {
          this.currentLKNr = lk.LieferkundeNr;
          this.cfg.getDomainSuggestions().subscribe(suggs => {
            if (suggs && suggs.length > 0) {
              this.showSuggestions(suggs);
            }
          });
        }
      }
    });
  }

  showSuggestions(suggs: DomainSuggestion[]) {
    this.dialog.open(AppDialogsDomainSuggestionsComponent, {
      data: suggs
    });
  }
}
