import {Component, Input} from '@angular/core';
import {Artikel} from '../../../../models/artikel';
import {View} from '../../../../models/view';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SharedDialogsContactFormComponent} from '../../dialogs/contact-form/shared.dialogs.contact-form.component';

@Component({
  selector: 'contact-button',
  templateUrl: 'shared.contact-button.component.html',
  styleUrls: ['shared.contact-button.component.scss']
})
export class SharedContactButtonComponent {
  @Input() artikel: Artikel;
  @Input() view: View;
  @Input() asbutton = false;

  constructor(private dialog: MatDialog) {
  }

  show() {
    this.dialog.open(SharedDialogsContactFormComponent, {
      data: {
        artikel: this.artikel,
        view: this.view
      }
    });
  }
}
