import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class SharedSvgIconService {
  constructor(private http: HttpClient) {
    this.load();
  }

  private booted = false;

  not_found = [];

  icon_storage_obs = new BehaviorSubject<any>({});

  icon_storage = {};
  icon_loaded = [];

  nf_timeout: any;

  iconlist: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  iconlistData = {};

  registerNotFound(name) {
    if (!this.not_found.includes(name)) {
      this.not_found.push(name);
    }
    if (this.nf_timeout) {
      clearTimeout(this.nf_timeout);
    }
    this.nf_timeout = setTimeout(() => {
      console.error('SVG IMG Missing: ', this.not_found);
    }, 200);
  }

  load() {
    if (!this.booted) {
      this.booted = true;

      this.http.get<any>(
        'assets/appicons/JGMPortalIcons.json',
      ).subscribe(ct => {
        this.iconlistData = ct.iconsData;
        this.iconlist.next(ct.icons);
      }, () => {
      });
    }
  }

  prepareIcon(text, color) {
    text = text.replaceAll('fill:#1d1d1b', 'fill:' + color);
    text = text.replace('width="64" height="64"', '');
    return text;
  }

  loadIcon(name, color) {
    if (!this.icon_loaded.includes(name + '_' + color)) {
      this.icon_loaded.push(name + '_' + color);
      const options = {
        responseType: 'text' as 'json',
        headers: new HttpHeaders().set('Content-Type', 'image/svg')
      };

      const iconpath = 'assets/appicons/icon_' + name + '.svg';

      if (this.iconlistData[name]) {
        let ct = this.prepareIcon(this.iconlistData[name], color);
        this.icon_storage[name + '_' + color] = ct;
        this.icon_storage_obs.next(this.icon_storage);
      } else {
        this.icon_storage[name + '_' + color] = name;
        this.icon_storage_obs.next(this.icon_storage);
      }
    }
  }

  getIcon(name, color): Observable<string> {
    const end$ = new Subject();
    return this.icon_storage_obs.asObservable().pipe(takeUntil(end$)).pipe(map(st => {
      if (st[name + '_' + color]) {
        end$.complete();
        return st[name + '_' + color];
      } else {
        this.loadIcon(name, color);
      }
    }));

  }
}
