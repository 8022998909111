import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppConfigService} from './app.config.service';
import {Notification} from '../models/notification';
import {User} from '../models/user';
import {AppUserService} from './app.user.service';
import {AppEventService} from './app.event.service';
import {AppMobileappService} from './app.mobileapp.service';
import {Router} from '@angular/router';
import {AppDialogsService} from './app.dialogs.service';
import {map} from 'rxjs/operators';

declare let window: any;

@Injectable({providedIn: 'root'})
export class AppNotificationService {

  url_get = '/notifications/get';
  url_get_all = '/notifications/getAll';
  url_set_last_id = '/notifications/setLastId';
  url_set_seen = '/notifications/setseen';
  url_send = '/notifications/send';

  notification_data: any[] = [];

  do_fetch = false;
  user: User;

  menu_visible = new BehaviorSubject<boolean>(false);
  menu_visible$ = this.menu_visible.asObservable();

  current_path = '';

  notifications = new BehaviorSubject<Notification[]>([]);
  notifications$ = this.notifications.asObservable();

  notifications_count = new BehaviorSubject<number>(0);
  notifications_count$ = this.notifications_count.asObservable();

  constructor(private http: HttpClient, private cfg: AppConfigService, private appsvc: AppMobileappService,
              private usvc: AppUserService, private esvc: AppEventService, private router: Router,
              private dsvc: AppDialogsService) {

    this.usvc.user$.subscribe(user => {
      if (user instanceof User) {
        if ((this.user instanceof User && this.user.id != user.id) || !this.user) {
          this.user = user;
          this.do_fetch = true;
          if (this.user.id) {
            this.load();
          }
        }

      } else {
        this.do_fetch = false;
      }
    });


    this.esvc.getQueue().subscribe(e => {
      if (e.name == 'App\\Events\\Notification\\NewNotificationEvent') {
        this.load();
      }
    });

    if (this.appsvc.isApp()) {
      const me = this;
      window.FirebasePlugin.onNotificationOpen(function (notification) {
        if (notification.tap == true) {
          if (notification.url != '') {
            me.router.navigateByUrl(notification.url);

          } else {
            me.menu_visible.next(true);
          }
        }
      }, function (error) {
        console.error(error);
      });
    }
  }

  toggleMenu() {
    if (this.menu_visible.getValue() == true) {
      this.menu_visible.next(false);
    } else {
      this.menu_visible.next(true);
    }
  }

  sendNotification(user_ids: number[], label: string, text: string, icon: string, url: string = '',
                   dismissable: boolean = true, is_popup: boolean = false): Observable<boolean> {
    return this.http.post<boolean>(
      this.cfg.buildUrl(this.url_send),
      {
        user_ids: user_ids,
        label: label,
        icon: icon,
        text: text,
        url: url,
        is_popup: is_popup,
        dismissable: dismissable,
      }
    );
  }

  isCurrentUser(testuser: User) {
    let state = false;

    if (testuser.id && this.user instanceof User && testuser.id == this.user.id) {
      state = true;
    }

    return state;
  }

  load() {
    if (this.do_fetch) {
      this.http.get<Notification[]>(
        this.cfg.buildUrl(this.url_get)
      ).subscribe(not => {
        if (not) {
          let reload = false;

          const next_not = [];
          not.forEach(n => {
            Object.setPrototypeOf(n, Notification.prototype);

            if (n.is_popup) {
              this.dsvc.notification(n, (n2, state) => {
                this.setSeen(n.id).subscribe(s => {
                  if (state) {
                    if (n.rendered.url) {
                      if (n.rendered.url.match(/^http:\/\/.*$/g) || n.rendered.url.match(/^https:\/\/.*$/g)) {
                        window.open(n.rendered.url, '_blank');
                      } else {
                        this.router.navigateByUrl(n.rendered.url);
                      }
                    }
                  }
                });
              });
            } else {
              if (n.getUrl(this) !== '') {
                if ((n.getUrl(this) === this.current_path || 'index_app.html' + n.getUrl(this) === this.current_path) && n.dismissable) {
                  reload = true;
                  this.setSeen(n.id).subscribe(state => {
                  });
                }
              }
              next_not.push(n);
            }
          });
          this.notifications_count.next(next_not.length);

          if (this.appsvc.isApp()) {
            window.FirebasePlugin.setBadgeNumber(not.length);
          }

          if (!reload) {
            this.notifications.next(next_not);
          } else {
            this.load();
          }
        }
      });
    }
  }

  addNotificationData(url: string, data: any) {
    this.notification_data[url] = data;
  }

  getNotificationData(url: string) {
    if (this.notification_data[url] !== undefined) {
      const ret = this.notification_data[url];
      delete this.notification_data[url];
      return ret;
    } else {
      return false;
    }
  }


  getAll(): Observable<Notification[]> {
    return this.http.get<Notification[]>(
      this.cfg.buildUrl(this.url_get_all)
    ).pipe(map(not => {
      if (not) {
        not.forEach(n => {
          Object.setPrototypeOf(n, Notification.prototype);
        });
      }
      return not;
    }));
  }

  setLastId(id) {
    return this.http.get<any>(
      this.cfg.buildUrl(this.url_set_last_id),
      {
        withCredentials: true,
        params: new HttpParams()
          .set('id', id.toString())
      }
    );
  }

  setAllSeen(ids) {
    return this.http.post<any>(
      this.cfg.buildUrl(this.url_set_seen),
      {
        ids: ids,
      }
    );
  }

  setSeen(id) {
    return this.http.get<any>(
      this.cfg.buildUrl(this.url_set_seen),
      {
        params: new HttpParams()
          .set('id', id.toString())
      }
    );
  }
}
