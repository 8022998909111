import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA} from '@angular/material/legacy-snack-bar';
import {MerklistActionInfo} from '../../../../models/merklist-action-info';

@Component({
  templateUrl: 'shop.merkliste-action-info.component.html',
  styleUrls: ['shop.merkliste-action-info.component.scss']
})
export class ShopMerklisteActionInfoComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: MerklistActionInfo) {

  }

  keydown(event) {
    event.stopPropagation();
  }
}
