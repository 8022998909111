<mat-form-field  matTooltip="{{file.path}}">
  <input matInput [value]="file.label" [disabled]="true" [placeholder]="placeholder">
  <span matSuffix>
    <button mat-icon-button (click)="pick()" matTooltip="Auswählen">
      <svg-icon>suche</svg-icon>
    </button>
    <button mat-icon-button (click)="clear()" [disabled]="!file.label" matTooltip="Löschen" style="margin-left: 15px">
      <svg-icon>loeschen</svg-icon>
    </button>
    <button mat-icon-button (click)="download()" [disabled]="!file.label" matTooltip="Herunterladen" style="margin-left: 15px">
      <svg-icon>downloads</svg-icon>
    </button>
  </span>
</mat-form-field>
