<div class="logincontentinner">
  <div>
    <div class="loginlogobox">
      <img class="logo" src="{{domaincfg.logofilename}}"/>
    </div>


    <div class="loginbox">
      <div class="bar">Neue IP freischalten</div>

      <div *ngIf="token && ip">
        <div *ngIf="!(message|async)">
          <h3>Wollten Sie die IP freischalten?</h3>
          <strong>{{ip}}</strong>

          <br>
          <br>
          <button [disabled]="loading|async" class="btn btn-primary" color="primary" mat-raised-button (click)="validate()">
            <svg-icon>success</svg-icon>
            Freischalten
          </button>
          <br>
          <br>
        </div>

        <div *ngIf="message|async">
          <h3>{{message|async}}</h3>

          <button [disabled]="loading|async" class="btn btn-primary" color="primary" mat-raised-button routerLink="/login">
            <svg-icon>login</svg-icon>
            Anmelden
          </button>
        </div>
      </div>
      <br>

      <button [disabled]="loading|async" class="btn btn-primary" color="accent" mat-raised-button routerLink="/login">
        <svg-icon>zurueck</svg-icon>
        Abbrechen
      </button>
    </div>
  </div>
</div>
