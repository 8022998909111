import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Event} from '../models/event';
import {BehaviorSubject, Observable} from 'rxjs';
import {AppConfigService} from './app.config.service';
import {AppMobileappService} from './app.mobileapp.service';
import {AppSystemService} from './app.system.service';
import {AppLogService} from './app.log.service';
import {AppPlatformService} from './app.platform.service';
import {Logger} from './app.logger.service';
import {AppDialogsService} from './app.dialogs.service';

declare let window: any;
declare let device: any;

@Injectable({providedIn: 'root'})
export class AppEventService {

  version: string = require('../../../package.json').version;

  url_get = '/events';
  load_interval = 2000;

  device_data = {
    fb_token: '',
    platform: '',
    model: '',
    os_version: '',
    manufacturer: ''
  };

  debug = false;
  queue = new BehaviorSubject<Event>(new Event());
  queue$ = this.queue.asObservable();


  init_locked = false;


  api_not_avaible = new BehaviorSubject<boolean>(false);
  api_not_avaible$ = this.api_not_avaible.asObservable();
  is_loggedin: boolean;
  inpersonated = false;
  socket_open_timer: any;
  socket_reopen_lock = false;
  socket: WebSocket;
  logsub = this.queue$.subscribe(e => {
    if (e && e.name) {
      if (e.name == 'App\\Events\\DomainSettings\\DomainSettingsChangedEvent') {
        if (e.data.id == this.cfg.domainconfig.id) {
          this.dsvc.confirm(
            'frage',
            'Konfiguration verändert',
            'Konfiguration muss neu geladen werden. Seite wird neu geladen. OK?',
            () => {
              window.location.reload();
            }
          );
        }
      } else if (e.name == 'App\\Events\\Api\\ApiOfflineEvent' || e.name == 'gui.api_offline') {
        this.api_not_avaible.next(true);
      } else if (e.name == 'App\\Events\\Api\\ApiOnlineEvent') {
        this.api_not_avaible.next(false);
      } else if (e.name == 'maintenance_mode_changed') {
        this.sys.checkMaintenanceMode();
      } else if (e.name == 'user_notloggedin') {
        if (window.location.pathname != '/' && window.location.pathname != '/login' && window.location.pathname != '/change-password') {
          window.location.href = '/';
        }
      } else if (e.name == 'gui.NavigationEnd') {
        this.sendPosition();
      }

      if (e.name == 'gui.user_loaded' || e.name == 'gui.user_loggedout') {
        this.logDebug('got user change', e);
        if (this.socket) {
          this.socket.close();
        }
        this.loadSessId();
      }

      if (e.name != 'gui.app_body_click') {
        this.logDebug('EVENT:', e.name, e);
      }
    }
  });
  session_id = '';

  pingpong: any;

  constructor(private http: HttpClient, private cfg: AppConfigService, private asvc: AppMobileappService,
              private sys: AppSystemService, private logsvc: AppLogService, private ps: AppPlatformService,
              private dsvc: AppDialogsService) {
    const svc = this;

    if (this.asvc.isApp()) {
      this.device_data.platform = device.platform;
      this.device_data.model = device.model;
      this.device_data.os_version = device.version;
      this.device_data.manufacturer = device.manufacturer;
      this.getFBToken();
    }
  }

  bootSVC() {
    this.log('Boot');
    this.loadSessId();
    this.initDebug();

  }

  handleMessage(data: any) {
    switch (data.action) {
      case 'events':
        this.handleEvents(data);
        break;
    }
  }

  handleEvents(data: any) {
    const events = data.data;
    if (events) {
      events.forEach(e => {
        Object.setPrototypeOf(e, Event.prototype);
        this.addEvent(e);
      });
    }
  }

  initSocket() {
    this.logDebug('initSocket');
    if ((!this.socket || !(this.socket.readyState === WebSocket.CONNECTING)) && this.session_id != '') {
      this.init_locked = true;
      this.logDebug('Connecting to ' + this.cfg.getEventSocketUrl() + '...');
      if (this.socket) {
        this.socket.close();
      }

      this.socket = new WebSocket(this.cfg.getEventSocketUrl());
      this.socket.onmessage = (e: MessageEvent) => {
        const data = JSON.parse(e.data);
        if (data.action) {
          this.handleMessage(data);
        }
      };

      this.socket.onopen = () => {
        this.init_locked = false;
        if (this.socket_open_timer) {
          clearTimeout(this.socket_open_timer);
        }

        this.sendSocketHello();
        this.sendPosition();
        this.startPingPong();
        this.logDebug('Socket ready');
      };

      this.socket.onclose = () => {
        this.init_locked = false;
        this.logDebug('Socket closed');
        if (this.socket_open_timer) {
          clearTimeout(this.socket_open_timer);
        }

        this.socket_open_timer = setTimeout(() => {
          this.logDebug('reopen Socket after onclose');
          this.initSocket();
        }, 1000);
      };

      this.socket.onerror = () => {
        this.init_locked = false;
        if (this.socket_open_timer) {
          clearTimeout(this.socket_open_timer);
        }
        this.init_locked = false;
        this.socket_open_timer = setTimeout(() => {
          this.logDebug('reopen Socket after onerror');
          this.initSocket();
        }, 1000);
      };

    } else if (this.socket && this.session_id == '') {
      this.logDebug('Socket closed');
      if (this.socket) {
        this.socket.close();
      }
      this.init_locked = false;

    } else {
      this.log('Not logged in');
      this.init_locked = false;
    }
  }

  inpersonate() {
    this.inpersonated = true;
  }

  depersonate() {
    this.inpersonated = false;
  }

  unsubDevice() {
    this.http.post<boolean>(
      this.cfg.buildUrl('/events/unsub_device'),
      {
        'token': this.device_data.fb_token
      }
    ).toPromise();
  }

  getFBToken() {
    const esvc = this;
    window.FirebasePlugin.getToken(function (fb_token) {
      // save this server-side and use it to push notifications to this device
      esvc.device_data.fb_token = fb_token;
    }, function (error) {
      console.error(error);
    });
  }

  setLoggedin() {
    if (this.is_loggedin == false) {
      this.addGuiEvent('loggedin');
    }
    this.is_loggedin = true;
  }

  setNotLoggedin() {
    this.is_loggedin = false;
  }

  addEvent(event: Event) {
    this.queue.next(event);
  }

  addGuiEvent(name: string, data: any = false) {
    const e = new Event();
    e.name = 'gui.' + name;
    if (data) {
      e.data = data;
    }
    this.addEvent(e);
  }

  isGuiEvent(event: Event, name: string) {
    if (event.name == 'gui.' + name) {
      return true;
    } else {
      return false;
    }
  }

  getQueue(): Observable<Event> {
    return this.queue$;
  }

  private startPingPong() {
    this.stopPingPong();
    Logger.info('EventSVC', 'PingPong', 'start');
    if (this.ps.isBrowser) {
      this.pingpong = setInterval(() => {
        this.sendPingPong();
      }, 10000);
    }
  }

  private sendPingPong() {
    this.sendData({'action': 'ping'}, false);
  }

  private stopPingPong() {
    if (this.pingpong) {
      Logger.info('EventSVC', 'PingPong', 'stop');
      clearInterval(this.pingpong);
    }
  }

  private sendData(data: any, retry = true) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify(data)
      );
    } else {
      if (retry) {
        setTimeout(() => {
          this.sendData(data);
        }, 200);
      }
    }
  }

  private log(message, ...optionalParams: any[]) {
    this.logsvc.consoleLog(this, message, ...optionalParams);
  }

  private logDebug(message, ...optionalParams: any[]) {
    this.logsvc.consoleDebug(this, message, ...optionalParams);
  }

  private sendPosition() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      let domainid = 0;
      if (this.cfg && this.cfg.domainconfig) {
        domainid = this.cfg.domainconfig.id;
      }
      this.socket.send(
        JSON.stringify({
          session_id: this.session_id,
          window_id: this.getWindowId(),
          action: 'update_current_position',
          domain_id: domainid,
          query: {
            route: this.cfg.active_route,
            component: this.cfg.active_component
          }
        })
      );
    }
  }

  private loadSessId(exec_after: any = false) {
    if (!this.init_locked) {
      this.init_locked = true;

      this.log('loadSessId');

      this.http.get<string>(
        this.cfg.buildUrl('/sessid')
      ).subscribe(sessid => {
        this.session_id = sessid;
        if (exec_after) {
          exec_after();
        } else {
          this.initSocket();
        }
      });
    }
  }

  private getWindowId() {
    if (typeof window !== 'undefined') {
      if (window.name == '') {
        const now = new Date();
        // noinspection JSConstantReassignment
        window.name = 'Window::' + Math.floor((Math.random() * 100) + 1) + ':' + now.getTime();
      }

      return window.name;

    } else {
      return 'dummy';
    }
  }

  private sendSocketHello() {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(
        JSON.stringify({
          action: 'hello',
          session_id: this.session_id,
          window_id: this.getWindowId(),
          endpoint: this.cfg.getEventSocketUrl(),
          appversion: this.version,
        })
      );
      this.sendPosition();
      // this.api_not_avaible.next(false);
    } else {
      setTimeout(() => {
        this.sendSocketHello();
      }, 200);
    }
  }

  private initDebug() {
    if (this.cfg.isDebug()) {
      this.debug = true;
    } else {
      this.debug = false;
    }
  }
}
