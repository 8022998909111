import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AppConfigService} from "../services/app.config.service";

@Injectable()
export class AppGuardsFeatureguard implements CanActivate, CanLoad, CanActivateChild {
  constructor(private cfg: AppConfigService) {
  }

  canActivate(route: ActivatedRouteSnapshot, rtsnap: RouterStateSnapshot): Observable<boolean> {
    if (route.data.features) {
      return of(this.cfg.featuresEnabled(route.data.features));
    } else {
      return of(true);
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (childRoute.data && childRoute.data.features) {
      return of(this.cfg.featuresEnabled(childRoute.data.features));
    } else {
      return of(true);
    }
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (route.data && route.data.features) {
      return of(this.cfg.featuresEnabled(route.data.features));
    } else {
      return of(true);
    }
  }
}
