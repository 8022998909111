import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Artikel} from '../../../../models/artikel';
import {View} from '../../../../models/view';
import {Router} from '@angular/router';
import {ContactFormData} from '../../models/contact-form-data';
import {ContactFormType} from '../../models/contact-form-type';
import {SharedContactService} from '../../services/shared.contact.service';
import {AppMessageService} from '../../../../services/app.message.service';
import {AppPageloaderService} from '../../../../services/app.pageloader.service';
import {AppConfigService} from "../../../../services/app.config.service";
import {AppMobileService} from "../../../../services/app.mobile.service";

@Component({
  templateUrl: 'shared.dialogs.contact-form.component.html',
  styleUrls: ['shared.dialogs.contact-form.component.scss']
})
export class SharedDialogsContactFormComponent {
  artikel: Artikel;
  view: View;

  fdata = new ContactFormData();

  types: ContactFormType[];


  constructor(public router: Router, private svc: SharedContactService, private msg: AppMessageService,
              public msvc: AppMobileService,
              public dialogRef: MatDialogRef<SharedDialogsContactFormComponent>, private loader: AppPageloaderService,
              private cfg: AppConfigService, @Inject(MAT_DIALOG_DATA) public data: { artikel: Artikel, view: View }) {
    this.artikel = data.artikel;
    this.view = data.view;
    this.fdata.url = window.location.href;
    this.initTypes();
  }

  initTypes() {
    this.types = [
      {
        id: 'article',
        icon: 'frage',
        label: (this.artikel instanceof Artikel) ? 'Frage zu ' + this.artikel.label + ' stellen' : '',
        show: (this.artikel instanceof Artikel && this.view instanceof View),
        setlabel: (this.artikel instanceof Artikel) ? 'Frage zu ' + this.artikel.label : ''
      },
      {
        id: 'view',
        icon: (this.view instanceof View) ? this.view.getIcon() : '',
        label: (this.view instanceof View) ? 'Frage zu ' + this.view.label + ' stellen' : '',
        show: (this.view instanceof View),
        setlabel: (this.view instanceof View) ? 'Frage zu ' + this.view.label : '',
      },
      {
        id: 'general',
        icon: 'info-anfragen',
        label: 'Allgemeine Frage stellen',
        show: true,
        setlabel: ''
      },
      {
        id: 'technical',
        icon: 'technische-anfrage',
        label: 'Technische Frage stellen',
        show: true,
        setlabel: ''
      },
    ];
    if (this.cfg.domainconfig.teamviewer_config_id) {
      this.types.push({
        id: 'fernwartung',
        icon: 'fernwartung-anfrage',
        label: 'Fernwartung starten',
        show: true,
        setlabel: ''
      });
    }
  }

  dlTeamviewer() {
    window.open('https://get.teamviewer.com/'+this.cfg.domainconfig.teamviewer_config_id);
  }

  selectType(type: ContactFormType) {
    if (type.id == 'fernwartung') {
      this.dlTeamviewer();
    } else {
      this.fdata.label = type.setlabel;
      this.fdata.type = type;
      this.fdata.artikel = this.artikel;
      if (this.view instanceof View) {
        this.fdata.view_id = this.view.artikelstamm + '_' + this.view.knoten_id;
      }
    }
  }

  send() {
    const l = this.loader.start();
    this.svc.sendForm(this.fdata).subscribe({
      next: (s) => {
        if (s) {
          this.msg.info('Danke für Ihre Anfrage!');
        } else {
          this.msg.info('Ihre Anfrage konnte leider nicht gesendet werden!');
        }
        l.stop();
      },
      error: () => {
        this.msg.info('Ihre Anfrage konnte leider nicht gesendet werden!');
        l.stop();
      }
    });
  }
}
