<div class="logincontentinner">
  <div>
    <div class="loginlogobox">
      <img class="logo" src="{{domaincfg.logofilename}}"/>
    </div>


    <div class="loginbox">
      <div class="bar">Passwort Rücksetzung</div>


      <div *ngIf="!token">
        <div *ngIf="!(message|async)">
          <p>Bitte geben Sie Ihre E-Mail Adresse ein</p>
          <mat-form-field>
            <input matInput [(ngModel)]="email" placeholder="E-Mail" email required #emailform="ngModel">
            <mat-error *ngIf="!emailform.valid">Ungültige E-Mail Adresse!</mat-error>
          </mat-form-field>

          <div style="height: 50px; font-size: 16px; color: red">
            {{error|async}}
          </div>

          <div class="form-group">
            <button [disabled]="(loading|async) || !emailform.valid || email == '' || send" class="btn btn-primary" color="primary" mat-raised-button (click)="send1()">
              <svg-icon>senden</svg-icon>
              Weiter
            </button>
            <div *ngIf="loading|async">
              <img
                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
            </div>
          </div>
        </div>

        <div *ngIf="message|async">
          <h3>{{message|async}}</h3>

          <button [disabled]="loading|async" class="btn btn-primary" color="primary" mat-raised-button routerLink="/login">
            <svg-icon>weiter</svg-icon>
            OK
          </button>
        </div>
      </div>

      <div *ngIf="token && frmgrp2">
        <form [formGroup]="frmgrp2">
          <div *ngIf="!(message|async)">
            <p>Bitte geben Sie Ihr neues Passwort ein</p>
            <mat-form-field style="width: 300px">
              <input matInput formControlName="password" type="password" placeholder="Passwort">
            </mat-form-field>

            <div style="text-align: left">
              <label
                class="col"
                [ngClass]="frmgrp2.controls['password'].hasError('required') || frmgrp2.controls['password'].hasError('minlength') || frmgrp2.controls['password'].hasError('maxlength')  ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp2.controls['password'].hasError('required') ||
                frmgrp2.controls['password'].hasError('minlength') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Zwischen 8 und 30 Zeichen!
                </div>
              </label>
              <br>

              <label
                class="col"
                [ngClass]="frmgrp2.controls['password'].hasError('required') || frmgrp2.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp2.controls['password'].hasError('required') ||
                frmgrp2.controls['password'].hasError('hasNumber') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Mindestens 1 Nummer!
                </div>
              </label>
              <br>

              <label
                class="col"
                [ngClass]="frmgrp2.controls['password'].hasError('required') || frmgrp2.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp2.controls['password'].hasError('required') ||
                frmgrp2.controls['password'].hasError('hasCapitalCase') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Mindestens 1 Großbuchstaben!
                </div>
              </label>
              <br>

              <label
                class="col"
                [ngClass]="frmgrp2.controls['password'].hasError('required') || frmgrp2.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp2.controls['password'].hasError('required') ||
                frmgrp2.controls['password'].hasError('hasSmallCase') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Mindestens 1 Kleinbuchstaben!
                </div>
              </label>
              <br>

              <label
                class="col"
                [ngClass]="frmgrp2.controls['password'].hasError('required') || frmgrp2.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp2.controls['password'].hasError('required') ||
                frmgrp2.controls['password'].hasError('hasSpecialCharacters') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top">
                  Mindestens 1 Sonderzeichen!
                </div>
              </label>
            </div>

            <br>
            <mat-form-field style="width: 300px">
              <input matInput formControlName="confirmPassword" type="password" placeholder="Passwort wiederholen">
            </mat-form-field>

            <div style="width: 280px; text-align: left" *ngIf="!frmgrp2.controls['password'].hasError('required')">
              <label
                class="col"
                [ngClass]="frmgrp2.controls['confirmPassword'].hasError('NoPasswordMatch') ? 'text-danger' : 'text-success'"
              >
                <i class="material-icons">
                  {{ frmgrp2.controls['confirmPassword'].hasError('NoPasswordMatch') ? 'cancel' :
                  'check_circle' }}
                </i>
                <div style="height: 24px; display: inline-block; vertical-align: top" *ngIf="frmgrp2.controls['confirmPassword'].hasError('NoPasswordMatch')">
                  Passwörter stimmen nicht überein!
                </div>
              </label>
            </div>

            <div style="height: 50px; font-size: 16px; color: red">
              {{error|async}}
            </div>

            <div class="form-group">
              <button [disabled]="(loading|async) || !frmgrp2.controls['password'].valid || !frmgrp2.controls['confirmPassword'].valid || send" class="btn btn-primary" color="primary" mat-raised-button (click)="send2()">
                <svg-icon>senden</svg-icon>
                Weiter
              </button>
              <div *ngIf="loading|async">
                <img
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
              </div>
            </div>
          </div>
        </form>

        <div *ngIf="message|async">
          <h3>{{message|async}}</h3>

          <button [disabled]="loading|async" class="btn btn-primary" color="primary" mat-raised-button routerLink="/login">
            <svg-icon>login</svg-icon>
            Anmelden
          </button>
        </div>
      </div>
      <br>

      <button [disabled]="loading|async" class="btn btn-primary" color="primary" mat-raised-button routerLink="/login">
        <svg-icon>zurueck</svg-icon>
        Abbrechen
      </button>
    </div>
  </div>
</div>
