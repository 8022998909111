import {Tutorialstep} from '../../models/tutorialstep';

export const APPTUTORIALS: Tutorialstep[] = [
  // Tutorial
  new Tutorialstep(
    '.tutorial_start',
    'Willkommen im Tutorial!<br>Per Klick können Sie zum nächsten Punkt gelangen.<br><br>Wenn es etwas zu erklären gibt, erscheine ich wieder!',
  ),
  new Tutorialstep(
    '.tutorial_skip_button',
    'Hier können Sie das Tutorial deaktivieren oder nur für die derzeitige Sitzung deaktivieren!<br>Dies kann in den Benutzer-Einstellungen derzeit geändert werden!'
  ),
// ---------------- NAVBAR ------------------------------------------------------------
  // MENU BUTTON
  new Tutorialstep(
    '.tut_menu_button',
    'Dies ist der Menü - Button. <br> Dieser dient der kompletten Navigation!'
  ),

  // WK BUTTON
  new Tutorialstep(
    '.tut_navbar_wkbutton',
    'Hier finden Sie Ihre Warenkörbe. Der aktive Warenkorb kann per Klick geändert werden.'
  ),

  // NOTIFICATION BUTTON
  new Tutorialstep(
    '.tut_navbar_notification',
    'Hier sind Benutzer-Benachrichtigungen zu finden!'
  ),

  // LIEFERKUNDE BUTTON
  new Tutorialstep(
    '.tut_lieferkunde_button',
    'Hier sehen Sie Ihren aktuell angemeldeten Kunden. Dieser kann jederzeit geändert werden!'
  ),

  // LIEFERKUNDE BUTTON
  new Tutorialstep(
    '.tut_user_menu',
    'Hier finden Sie Ihr Benutzer - Menü!'
  ),

// ---------------- SECTION TITLE ------------------------------------------------------------
  new Tutorialstep(
    '.tut_back_button',
    'Dieser Button führt Sie immer zur vorherigen Ansicht zurück!'
  ),
// ---------------- LIEFERKUNDE AUSWHAL ------------------------------------------------------------
  // LIEFERKUNDE SELECTOR
  new Tutorialstep(
    '.tut_lieferkunden_selector',
    'Wählen Sie hier einen Lieferkunden aus um loszulegen!'
  ),


// ---------------- DASHBOARD ------------------------------------------------------------
  // Dashboard
  new Tutorialstep(
    '.tut_dashboard_start',
    '<h3>Willkommen im Dashboard!</h3> <br> Hier finden Sie die wichtigsten Informationen auf einen Blick!'
  ),


  new Tutorialstep(
    '.tut_edit_dashboard',
    'Hier Können Sie die Bearbeitung Ihres individuellen Dashboards starten!'
  ),

  new Tutorialstep(
    '.tut_edit_dashboard_add_widget',
    'Hier Können Sie Widgets hinzufügen.'
  ),

  new Tutorialstep(
    '.tut_edit_dashboard_reset',
    'Hier Können Sie das Dashboard auf die Werkseinstellungen zurücksetzen.'
  ),

  new Tutorialstep(
    '.tut_edit_dashboard_add_save',
    'Hier Können Sie Ihr Dashboard speichern.'
  ),

  new Tutorialstep(
    '.tut_edit_dashboard_add_cancel',
    'Hier Können Sie die Bearbeitung abbrechen.'
  ),

  new Tutorialstep(
    '.tut_dashboard_edit_drag',
    'Hiermit können Sie widgets verschieben. (An der rechten unteren Widget-Ecke kann die Größe angepasst werden)'
  ),

  new Tutorialstep(
    '.tut_dashboard_edit_rm',
    'Hiermit können Sie das Widget entfernen.'
  ),

// ---------------- MAIN MENU ------------------------------------------------------------
  new Tutorialstep(
    '.tut_mainmenu',
    'Willkommen im Hauptmenü. Hier können Sie zu allen wichtigen Funktionsbereichen gelangen!'
  ),

  new Tutorialstep(
    '.tut_mainmenu_dashboard',
    'Hier geht´s zurück zum Dashboard.'
  ),

  new Tutorialstep(
    '.tut_mainmenu_calendar',
    'Hier geht´s zum Kalender. Hier finden Sie wichtige Termine und Veranstaltungen.'
  ),

  new Tutorialstep(
    '.tut_mainmenu_shop_menu',
    'Hier geht´s zum Shop Bereich! Dieser beinhaltet auch Warenkörbe und Merkzettel.'
  ),

  new Tutorialstep(
    '.tut_mainmenu_enrollment_menu',
    'Hier geht´s zum Meldungsbereich! Hier gibt´s z.B. die Beipackmeldung oder Preissenkungsmeldungen.'
  ),

  new Tutorialstep(
    '.tut_mainmenu_tourplan',
    'Hier geht´s zu Ihren Touren. (Ihre Anlieferungszeiten)'
  ),

  new Tutorialstep(
    '.tut_mainmenu_callplan',
    'Hier geht´s zum Anrufplan. (Ihre Rufzeiten)'
  ),

  new Tutorialstep(
    '.tut_mainmenu_last-chance-articles',
    'Hier finden Sie durch uns noch lieferbare Artikel. Diese sind in Kürze nicht mehr erhältlich.'
  ),

  new Tutorialstep(
    '.tut_mainmenu_cashbook',
    'Hier finden Sie das Kassabuch.'
  ),

  new Tutorialstep(
    '.tut_mainmenu_downloads',
    'Hier finden Sie Ihre Downloads.'
  ),

  new Tutorialstep(
    '.tut_mainmenu_analyse_menu',
    'Hier finden Sie den Analyse-Bereich. (Statistiken uvm.)'
  ),

  new Tutorialstep(
    '.tut_mainmenu_customer-settings',
    'Hier geht´s zu Ihren Kunden-Einstellungen. (Autom. Importabschluss, Bestellbestätigung)'
  ),

  new Tutorialstep(
    '.tut_mainmenu_customer-settings2',
    'Hier geht´s zu Ihren Kunden-Einstellungen. (Bestellbestätigung)'
  ),

  new Tutorialstep(
    '.tut_mainmenu_admin',
    'Hier geht´s zum Admin-Bereich.'
  ),

  new Tutorialstep(
    '.tut_mainmenu_account',
    'Hier geht´s zu Ihrem Account. <br> Dort können Sie Einstellungen vornehmen und Ihr Passwort ändern.'
  ),


// ---------------- DIV GLOBALE ELEMENTE --------------------------------------------------
  new Tutorialstep(
    '.tut_impticker',
    'Hier sehen Sie die verbleibende Zeit zum automatischen Importabschluss. <br> ' +
    '(Falls Sie diesen in Ihren Kundeneinstellungen nicht deaktiviert haben)'
  ),

// ---------------- SHOP MENU ------------------------------------------------------------
  new Tutorialstep(
    '.tut_shopmenu_warenkorb',
    'Hier geht´s zur Warenkorb Verwaltung.'
  ),

  new Tutorialstep(
    '.tut_shopmenu_merklisten',
    'Hier geht´s zur Merklisten Verwaltung.'
  ),

  new Tutorialstep(
    '.tut_shopmenu_bestellungen',
    'Hier geht´s zu Ihren Web-Bestellungen.'
  ),

  new Tutorialstep(
    '.tut_shopmenu_views',
    'Hier sehen Sie Ihre freigeschalteten Shop-Bereiche.'
  ),

// ---------------- WARENKORB SELECTOR ------------------------------------------------------------
  new Tutorialstep(
    '.tut_warenkorb_selector .tut_warenkorb_table',
    'Hier sehen Sie die von Ihnen angelegten Warenkörbe'
  ),

  new Tutorialstep(
    '.tut_warenkorb_selector .tut_warenkorb_primary',
    'Hier sehen Sie ob dies der aktive Warenkorb ist.'
  ),

  new Tutorialstep(
    '.tut_warenkorb_selector .tut_warenkorb_owner',
    'Dies zeigt an, dass Sie der Besitzer dieses Warenkorbs sind.'
  ),

  new Tutorialstep(
    '.tut_warenkorb_selector .tut_warenkorb_shared',
    'Dies zeigt an, dass der Warenkorb mit allen in Ihrem Unternehmen geteilt wird.'
  ),

  new Tutorialstep(
    '.tut_warenkorb_selector .tut_warenkorb_new',
    'Hier kann ein neuer Warenkorb angelegt werden.'
  ),

// ---------------- WARENKORB ------------------------------------------------------------
  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_setprimary',
    'Hier kann der Warenkorb zum aktiven Warenkorb gemacht werden.'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_clear',
    'Hier kann der Warenkorb geleert werden.'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_rename',
    'Hier kann der Warenkorb umbenannt werden.'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_delete',
    'Hier kann der Warenkorb gelöscht werden.'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_group',
    'Hier kann zwischen der gruppierten und nicht gruppierten Ansicht gewechselt werden.'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_impsend',
    'Hier kann der automatische Import-Abschluss für diesen Warenkorb deaktiviert werden.'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_note',
    'Hier können Sie sich eine für Sie interne Notiz machen. Diese wird nicht an uns übertragen!'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_menge',
    'Hier kann jederzeit die gewünschte Menge eingegeben werden.'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_duplicate',
    'Hier kann die aktuelle Zeile dupliziert werden.'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_artnote',
    'Hier kann eine Notiz-Zeile für Sie selbst hinzugefügt werden.'
  ),
  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_artdelete',
    'Hier kann die Zeile gelöscht werden.'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_vf',
    'Hier kann die Artikel-Verfügbarkeit geprüft werden.'
  ),

  new Tutorialstep(
    '.tut_warenkorb .tut_warenkorb_send',
    'Hier können Sie den Warenkorb absenden.'
  ),

// ---------------- MERKLISTEN SELECTOR ------------------------------------------------------------
  new Tutorialstep(
    '.tut_merklistenselector .tut_merkliste_table',
    'Hier sehen Sie die von Ihnen angelegten Merklisten'
  ),

  new Tutorialstep(
    '.tut_merklistenselector .tut_merkliste_primary',
    'Hier sehen Sie ob dies die aktive Merkliste ist.'
  ),

  new Tutorialstep(
    '.tut_merklistenselector .tut_merkliste_owner',
    'Dies zeigt an, dass Sie der Besitzer dieser Merkliste sind.'
  ),

  new Tutorialstep(
    '.tut_merklistenselector .tut_merkliste_shared',
    'Dies zeigt an, dass die Merkliste mit allen Benutzern in Ihrem Unternehmen geteilt wird.'
  ),

  new Tutorialstep(
    '.tut_merklistenselector .tut_merkliste_new',
    'Hier kann eine neue Merkliste angelegt werden.'
  ),
// ---------------- MERKLISTE ------------------------------------------------------------
  new Tutorialstep(
    '.tut_merkliste .tut_merkliste_setprimary',
    'Hier kann die Merkliste zur aktiven Merkliste gemacht werden.'
  ),

  new Tutorialstep(
    '.tut_merkliste .tut_merkliste_rename',
    'Hier kann die Merkliste umbenannt werden.'
  ),

  new Tutorialstep(
    '.tut_merkliste .tut_merkliste_delete',
    'Hier kann die Merkliste gelöscht werden.'
  ),

  new Tutorialstep(
    '.tut_merkliste .tut_merkliste_artwk',
    'Hier kann die aktuelle Zeile dupliziert werden.'
  ),

  new Tutorialstep(
    '.tut_merkliste .tut_merkliste_artnote',
    'Hier kann eine Notiz-Zeile für Sie selbst hinzugefügt werden.'
  ),
  new Tutorialstep(
    '.tut_merkliste .tut_merkliste_artdelete',
    'Hier kann die Zeile gelöscht werden.'
  ),

  new Tutorialstep(
    '.tut_merkliste .tut_merkliste_artvf',
    'Hier kann die Artikel-Verfügbarkeit geprüft werden.'
  ),
// ---------------- SHOP VIEW ------------------------------------------------------------
  new Tutorialstep(
    '.tut_shopview_ajaxwk',
    'Dies ist der Seiten-Warenkorb. Dieser funktioniert wie der normale Warenkorb.'
  ),

  new Tutorialstep(
    '.tut_shopview_ajaxwktoggle',
    'Hier kann der Seiten-Warenkorb ein bzw. ausgeklappt werden.'
  ),

  new Tutorialstep(
    '.tut_shopview_filter',
    'Hier kann nach div. Eigenschaften gefilter werden.'
  ),

  new Tutorialstep(
    '.tut_shopview_search',
    'Hier können Sie suchen.'
  ),

  new Tutorialstep(
    '.tut_shopview_view_tab',
    'Hier können Sie auf die Tabellen-Ansicht wechseln.'
  ),

  new Tutorialstep(
    '.tut_shopview_view_box',
    'Hier können Sie auf die Box-Ansicht wechseln.'
  ),

  new Tutorialstep(
    '.tut_shopview_wkbestellblockmodus',
    'Hier klicken um alle Artikel mit einer Menge > 0 in den Warenkorb zu legen.'
  ),

  new Tutorialstep(
    '.tut_shopview_count',
    'Hier sehen Sie, wieviele Einträge bereits geladen wurden. Wenn Sie nach unten scrollen, werden Einträge automatisch nachgeladen.'
  ),

  new Tutorialstep(
    '.tut_shopview_fields',
    'Hier können Sie die anzuzeigenden Felder auswählen. Dies wird für Sie pro Ansicht (Box/Tabellarisch) gespeichert.'
  ),

  new Tutorialstep(
    '.tut_wkpos_vertr tut_wkpos_amount',
    'Hier können Sie die Kondition des hinzuzufügenden Artikels bestimmen.'
  ),

  new Tutorialstep(
    '.tut_shopview tut_wkpos_amount',
    'Hier können Sie die Menge bestimmen.'
  ),

  new Tutorialstep(
    '.tut_shopview_artwk',
    'Drücken um die bestimmte Menge in den Warenkorb zu legen. (R-Klick für weitere Optionen)'
  ),

  new Tutorialstep(
    '.tut_shopview_artwkvertr',
    'Drücken um den Dialog für Hinzufügen mit Kondition zu öffnen.'
  ),

  new Tutorialstep(
    '.tut_shopview_artvf',
    'Drücken um den Lagerstatus der bestimmten Menge zu prüfen.'
  ),

  new Tutorialstep(
    '.tut_shopview_contact',
    'Drücken Sie hier um eine Anfrage zu stellen.'
  ),

  new Tutorialstep(
    '.tut_shopview_artml',
    'Drücken um den Artikel auf die Merkliste zu setzen. (R-Klick für weitere Optionen)'
  ),
// ---------------- ENROLLMENT MENU ------------------------------------------------------------
  new Tutorialstep(
    '.tut_enrollmenu_pricechange',
    'Hier geht\'s zu den Preissenkungen.'
  ),

  new Tutorialstep(
    '.tut_enrollmenu_enclosedshipping',
    'Hier können Sie eine Beipack-Sendung aufgeben.'
  ),

  new Tutorialstep(
    '.tut_enrollmenu_preorder',
    'Hier können Sie Artikel Vorbestellen.'
  ),

// ---------------- KALENDER  --------------------------------------------------------------
  new Tutorialstep(
    '.tut_calendar_bookedevents',
    'Hier finden Sie die von Ihnen gebuchten Veranstaltungen.'
  ),

  new Tutorialstep(
    '.tut_calendar_navi',
    'Hiermit können Sie innerhalb des Kalender navigieren. Sie können auch die Ansicht ändern.'
  ),

  new Tutorialstep(
    '.tut_calendar_catfilter',
    'Hiermit können Sie Einträge nach Kategorie ein bzw. ausblenden.'
  ),

  new Tutorialstep(
    '.tut_calendar_event',
    'Klicken Sie auf einen Eintrag umd Details zu erhalten.'
  ),

// ---------------- PRICE CHANGE LIST --------------------------------------------------------------
  new Tutorialstep(
    '.tut_price-change_list',
    'Hier sehen Sie alle verfügbaren Preissenkungen.'
  ),
// ---------------- PRICE CHANGE EDITOR ------------------------------------------------------------
  new Tutorialstep(
    '.tut_price-change_save',
    'Ihre Eingaben können jederzeit gespeichert werden um später weiter daran zu arbeiten.'
  ),
  new Tutorialstep(
    '.tut_price-change_avsimp',
    'Hier können Daten aus AVS importiert werden.'
  ),

  new Tutorialstep(
    '.tut_price-change_send',
    'Hierdurch können Sie Ihre Eingaben übermitteln.'
  ),

  new Tutorialstep(
    '.tut_price-change_toggle',
    'Hierdurch können die Tabellen pro Herstelle ein- bzw. ausgeblendet werden..'
  ),

  new Tutorialstep(
    '.tut_price-change_count',
    'Hinterlegen Sie hier Ihre lagernden Mengen.'
  ),

// ---------------- ENCLOSED SHIPPING ------------------------------------------------------------
  new Tutorialstep(
    '.tut_enclosed-shipping_new',
    'Hier kann eine neue Beipacksendung beauftragt werden.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping_list',
    'Hier sehen Sie alle bisher beauftragten Beipacksendung.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping_list_reprint',
    'Hier können die Dokumente nachgedruckt werden.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping_list_storno',
    'Hier können Sie Sendungen stornieren.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .mat-horizontal-stepper-header-container',
    'Hier sehen Sie den Fortschritt Ihrer Eingabe.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_select_type',
    'Bitte wählen Sie zuerst die Zielgruppe aus.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_betriebsstaette_select',
    'Bitte wählen Sie nun die gewünschte Ziel-Betriebsstätte aus.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_betriebsstaette_tohands',
    'An wen soll die Sendung zugestellt werden?.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_tourbrowser',
    'Bitte wählen Sie nun einen Abholzeitpunkt aus. Dies ist immer nur ab dem Folgetag möglich.<br>' +
    'Ist also Mittwoch, beginnt die Woche ab Donnerstag. Wählen Sie eine Abholung am Mittwoch aus, ist dies der Mittwoch in der Folgewoche.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_express',
    'Sie können auch eine Express-Abholung auswählen. Hier ist allerdings nur ein Packstück möglich!'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_customer',
    'Hier kann nach Apotheken in unserem Liefer-Netzwerk gesucht werden. <br>' +
    'Bitte wählen Sie nun eine aus.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_customerselect',
    'Wählen Sie nun den Kunden aus.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_addpackitem',
    'Hier können Sie Ihrer Sendung Packstücke hinzufügen. Von der Art der Packstücke hängt der errechnete Preis ab.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_packlist',
    'Hier sehen Sie nun Ihre hinzugefügten Packstücke.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_packlist_delete',
    'Packstücke können jederzeit wieder entfernt werden.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_pricelist',
    'Diese Tabelle zeigt die errechneten Preise an.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_noteselect',
    'Sie können optional eine Notiz hinterlegen.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_agb',
    'Bitte lesen Sie unsere Bestimmungen sorgfältig durch und stimmen Sie diesen zu.'
  ),

  new Tutorialstep(
    '.tut_enclosed-shipping .tut_enclosed-shipping_check',
    'Bitte prüfen Sie nun Ihre Eingaben und klicken Sie anschließend auf Kostenpflichtig beauftragen. <br>' +
    'Bitte drucken Sie den Beleg dann aus.'
  ),

// ---------------- PRE ORDER ------------------------------------------------------------
  new Tutorialstep(
    '.tut_preorder_list',
    'Hier werden alle für Sie verfügbaren Vorbestellungen angezeigt!'
  ),

  new Tutorialstep(
    '.tut_preorder_view_customer_amount',
    'Hier können Sie Ihre gewünschte Menge hinterlegen.'
  ),

  new Tutorialstep(
    '.tut_preorder_view_processed_amount',
    'Hier finden Sie die von uns zugesagte Menge!'
  ),

  new Tutorialstep(
    '.tut_preorder_view_processed_info',
    'Hier finden Sie das Feedback unserer Mitarbeiter!'
  ),

  new Tutorialstep(
    '.tut_preorder_view_send',
    'Hier können Sie Ihre Vorbestellung übermitteln!'
  ),

// ---------------- LAST CHANCE ARTICLES ------------------------------------------------------------
  new Tutorialstep(
    '.tut_last_chance_artikel',
    'Hier finden Sie Artikel, welche nicht mehr lieferbar sind, wir jedoch noch wenige Stück für Sie auf Lager haben!<br>' +
    'Sie können diese auch direkt bei uns bestellen!'
  ),
// ---------------- ANALYTICS MENU ------------------------------------------------------------
  new Tutorialstep(
    '.tut_menu_analytics_docsearch',
    'Hier können Sie unser Dokumenten-Archiv nach Lieferscheinen und Rechnungen durchsuchen.'
  ),
  new Tutorialstep(
    '.tut_menu_analytics_batchdocu',
    'Hier können Sie sich die Chargen-Aufzeichnung zu Ihren Lieferungen ansehen.'
  ),

  new Tutorialstep(
    '.tut_menu_analytics_order-archive',
    'Hier können Sie all Ihre Aufträge anschauen.'
  ),

  new Tutorialstep(
    '.tut_menu_analytics_residues',
    'Hier können Sie Ihre Rückstände einsehen und Änderungswünsche übermitteln.'
  ),
  new Tutorialstep(
    '.tut_menu_analytics_delivery_pharmacy',
    'Hier finden Sie die Bestellungen Ihrer Hausapotheken-Kunden.'
  ),
  new Tutorialstep(
    '.tut_menu_analytics_statistics',
    'Hier finden Sie diverse Statistiken.'
  ),
// ---------------- AUFTRAGS ARCHIV ------------------------------------------------------------
  new Tutorialstep(
    '.tut_order-archive_import',
    'Hier können Sie die Suche für Import-Aufträge aktivieren.'
  ),

  new Tutorialstep(
    '.tut_order-archive_search',
    'Hier können Sie per Artikel filtern.'
  ),

  new Tutorialstep(
    '.tut_order-archive_date',
    'Hier können Sie das Datum der Suche einschränken.'
  ),

  new Tutorialstep(
    '.tut_order-archive_bill',
    'Sie können sich per Klick auf die Zeile Details anzeigen lassen.'
  ),
  new Tutorialstep(
    '.tut_order-archive_showdoc',
    'Hier können Sie den dazugehörigen Beleg anschauen.'
  ),
// ---------------- Statistiken ------------------------------------------------------------
  new Tutorialstep(
    '.tut_statistics_date',
    'Hier kann der gewünschte Zeitraum geändert werden.'
  ),
  new Tutorialstep(
    '.tut_statistics_reset',
    'Hier können die Einstellungen zurücksetzen.'
  ),
  new Tutorialstep(
    '.tut_statistics_lks',
    'Hier können Sie die zu berücksichtigenden Lieferanschriften bestimmen.'
  ),
  new Tutorialstep(
    '.tut_statistics_navbar',
    'Hier können Sie zwischen den Statistiken wechseln.'
  ),
];
