import {Component, Inject} from '@angular/core';
import {WarenkorbEntry} from '../../../../models/warenkorb-entry';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AppWarenkorbService} from '../../../../services/app.warenkorb.service';
import {Warenkorb} from '../../../../models/warenkorb';
import {AppViewService} from '../../../../services/app.view.service';
import {AppPageloaderService} from '../../../../services/app.pageloader.service';
import {AppMessageService} from '../../../../services/app.message.service';

@Component({
  templateUrl: 'shop.dialogs.vertreter-add-to-warenkorb.component.html',
  styleUrls: ['shop.dialogs.vertreter-add-to-warenkorb.component.scss']
})
export class ShopDialogsVertreterAddToWarenkorbComponent {

  wk: Warenkorb;
  entry: WarenkorbEntry;

  disableDialog = false;
  disabled = false;

  vertreterconfig: any;


  constructor(public dialogRef: MatDialogRef<ShopDialogsVertreterAddToWarenkorbComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              public wksvc: AppWarenkorbService, public vsvc: AppViewService, private loader: AppPageloaderService,
              private msg: AppMessageService) {
    this.entry = data;
    this.wk = wksvc.primary_warenkorb.value;
    this.vertreterconfig = vsvc.$vertreterconfig;
  }

  change() {
  }

  add() {
    const l = this.loader.start();
    this.wksvc.addToWarenkorbById(this.wk.id, this.entry.view_slug, this.entry).subscribe({
      next: (s) => {
        if (s) {
          this.msg.info('Hinzugefügt!');
        } else {
          this.msg.info('Fehlgeschlagen!');
        }
        l.stop();
        this.close();
      }, error: (e) => {
        l.stop();
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

}
